:root {
  --blue: #0069c9;
  --yellow: #ffab15;
}
.aligncenter {
  text-align: center !important;
}
.color-grey {
  color: #757575 !important;
}
.customcardlc12cust {
  border-radius: 7px !important;
}
.customcardlc12cust:hover {
  box-shadow: 5px 5px 10px #aaaaaa !important;
}
.liveclass .csttextlc {
  color: grey !important;
  font-weight: 700 !important;
}
.recordedclass .csttextlc {
  color: black !important;
  font-weight: 700 !important;
}
.text-withunderline {
  text-decoration: underline solid #ffaa15 3px !important;
  text-underline-position: under !important;
}
.recordedclass .search-form {
  border: 1px solid black !important;
  width: 38% !important;
}
.custbtngp > .MuiToggleButtonGroup-root > .MuiToggleButton-root.Mui-selected {
  color: white !important;
  background: #1875f0 !important;
  border-color: #1875f0 !important;
}
.custgridkid {
  padding: 1% 2% !important;
}
.kidtitle {
  padding: 20px 0px 20px 10px;
  /* color: white; */
}
.link_register .MuiGrid-grid-xs-12 {
  padding: 2% !important;
}
.custbtngp
  > .MuiToggleButtonGroup-root
  > .MuiToggleButton-root.Mui-selected
  .trigg1 {
  display: block !important;
}
.custbtngp
  > .MuiToggleButtonGroup-root
  > .MuiToggleButton-root.Mui-selected
  .MuiTypography-root {
  color: white !important;
}

.custbtngp
  > .MuiToggleButtonGroup-root
  > .MuiToggleButton-root.Mui-selected:hover {
  color: white !important;
}
.cstvideotitle {
  position: absolute;
  top: 85px;
  left: 20px;
  color: white;
  font-size: 20px !important;
}
.cstvideotitle2 {
  position: absolute;
  top: 120px;
  left: 20px;
  color: white;
  font-size: 20px !important;
}
.custombody {
  color: grey;
}
/* .custbtngp .MuiToggleButtonGroup-grouped{ width: 180px;
    height: 100px; } */
.custombody .MuiGrid-root {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.custombody .MuiToggleButtonGroup-root {
  float: left;
}
.custombody .MuiToggleButton-root {
  font-size: 12.8px;
  font-weight: 500;
}
.registerbtn {
  background-color: #4caf50 !important;
  color: white !important;
}
.registerbtn:hover {
  /* border-bottom: 2px solid #FFAB15 !important; */
  color: black !important;
  background-color: #f4ae2d !important;
}
.custombody .MuiTypography-h5 {
  font-size: 1.3rem !important;
  text-align: left !important;
}
.custombody .makeStyles-root1-11 > * {
  width: 95% !important;
}
.Muicust {
  width: 95% !important;
}
.custombody .MuiAccordionSummary-root:hover {
  background-color: #cecece !important;
}
.liveclass_details .custombtnlcd {
  cursor: pointer;
  text-align: right;
  color: #0069c9 !important;
}
#acco1 {
  padding-right: 30px !important;
}
@media only screen and (max-width: 800px) {
  #acco1 {
    padding-right: 0px !important;
  }
  .custbtngp .MuiToggleButtonGroup-grouped {
    width: 100% !important;
    height: auto !important;
  }
  .custombody .MuiGrid-grid-xs-4,
  .custombody .MuiGrid-grid-xs-7 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .custombody .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 37.667% !important;
    flex-basis: 37.667% !important;
  }
  /* #search1>.makeStyles-searchroot-10 {
        width: 90% !important; 
    }
    #search1{
        margin-left: -7% !important;
    } */
}

.textalignleft {
  text-align: left !important;
}

/* .subheader{ margin-left: 3.2% !important;} */
.subheader .MuiCardContent-root {
  padding: 20px 10px !important;
  padding-bottom: 16px !important;
}
.subheader .MuiCard-root {
  max-width: 90% !important;
}
.subheader {
  padding: 2% 8.5% 0 10% !important;
}

.profile {
  padding: 0% 9.699% !important;
}
.profile .cardpr {
  padding-left: 2.8% !important;
}
.btn-new {
  background-color: #f59107 !important;
  color: white !important;
  border-radius: 50px !important;
}
.textleftalign {
  text-align: left;
}
.profile .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.profile .csttxt {
  font-size: 17px !important;
  font-weight: 600 !important;
}
.profile .csttxt1 {
  font-size: 40px !important;
  font-weight: bolder;
  text-align: right;
}

.cmn-plan-dashboard-link {
  color: white;
  position: relative;
  top: 15px;
  background-color: #0069c9;
  border-radius: 20px;
  padding: 7px 13px;
}
.cmn-plan-dashboard-link:hover {
  background-color: #0069c9;
  color: white;
}

.bookNew {
  color: white;
  position: relative;
  top: 46px;
  background-color: #0069c9;
  border-radius: 20px;
  padding: 7px 13px;
}
.bookNew:hover,
.bookNew:active {
  background-color: #0069c9;
  color: white;
}
.profile .MuiButton-root {
  text-transform: unset;
}
/* .profile .MuiButton-text {
    padding: 5px 16px;
    font-size: 12px;
} */

.MuiLink-root > .active {
  background-color: #0069c9 !important;
  color: white !important;
}
.MuiLink-root > .active .MuiTypography-root {
  color: white !important;
}

.cmn-plan .csttxt {
  font-size: 18px !important;
  font-weight: 600 !important;
}
.cmn-plan .csttxt1 {
  font-size: 40px !important;
  font-weight: bolder;
  text-align: right;
}
.cmn-plan .MuiButton-root {
  text-transform: unset;
}
.cmn-plan .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.cmn-plan .MuiButton-text {
  padding: 1px 8px !important;
}
.subscription .MuiButton-root {
  text-transform: unset;
}

.subscription .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.link_register .no-padding {
  padding: 0px !important;
}
.link_register .customalert {
  margin-bottom: 0% !important;
}
.ageselect {
  width: 100% !important;
}
.subscription .MuiOutlinedInput-root {
  border-radius: 20px !important;
}
.subscription .MuiDivider-root {
  background-color: rgba(217, 208, 208, 0.69) !important;
}
.subscription .MuiToggleButton-label {
  text-transform: initial !important;
}

.subscription .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.subscription .MuiToggleButton-root {
  border-radius: 5px !important;
}
.subscription .MuiToggleButton-root {
  padding: unset !important;
}
.subscription .amountcs {
  background-color: rgb(246, 246, 246);
}

.subscription
  .custbtngp
  > .MuiToggleButtonGroup-root
  > .MuiToggleButton-root.Mui-selected
  .amountcs {
  background-color: #1875f0 !important;
}
.subscription .MuiGrid-item {
  text-align: left;
}
.subscription .deleteicon .MuiSvgIcon-root {
  /* background-color: red !important;
border-radius: 50% !important;
padding: 8% !important; */
  color: red !important;
}
.link_register .deleteicon .MuiSvgIcon-root {
  color: red !important;
}
/*  .subscription .MuiButton-text{
     padding: 1px 8px !important;
} 
.subscription .MuiButton-root {
    font-size: 13px !important;
} */
.subscription .deleteicon {
  padding: 6px 8px !important;
}
.react-confirm-alert-body {
  /* background-color: #1875f0!important; */
  background-color: #fff !important;
  color: #000 !important;
}
.react-confirm-alert-button-group > button {
  background-color: #1875f0 !important;
}

.custbtngp1
  > .MuiToggleButtonGroup-root
  > .MuiToggleButton-root.Mui-selected
  .trigg1 {
  display: block !important;
}
.custbtngp1 > .MuiToggleButtonGroup-root .MuiToggleButton-label {
  color: white !important;
}
.custbtngp1 > .MuiToggleButtonGroup-root > .MuiButtonBase-root {
  border-radius: 5% !important;
}

.classes_css .custbtngp2 > .MuiToggleButtonGroup-root > .Mui-selected {
  background-color: #1875f0 !important;
  color: white !important;
}
.classes_css .MuiTab-root {
  min-width: 135px !important;
}
.classes_css .MuiTreeItem-label {
  text-align: left !important;
}
.classes_css .MuiTreeItem-label {
  color: black !important;
  font-weight: 600;
}
.classes_css .cstreetext .MuiTreeItem-label {
  font-size: 12px !important;
  color: grey !important;
  font-weight: 400;
  line-height: 1.6;
}
.classes_css .MuiTab-root {
  font-size: 15px !important;
  font-weight: 600;
  color: black !important;
}
.classes_css .MuiTabs-indicator {
  background-color: var(--yellow) !important;
  height: 3px !important;
}
.classes_css .MuiCardContent-root .MuiTypography-root {
  text-align: left !important;
}

.classes_css .cardhead {
  font-size: 30px !important;
  font-weight: bolder !important;

  padding-bottom: 1% !important;
}
.classes_css .customcardbs9 {
  padding: 22px 20px !important;
}
.common_classes .cardsublink {
  font-size: 14px !important;
  color: grey !important;
  padding-top: 1% !important;
}
.common_classes .cardsublink:hover {
  color: #01619d !important;
}
.common_classes .classes_css .btncst {
  border-radius: 20px !important;
  padding: 5px 30px !important;
}
.liveclass_details .applybtn {
  padding: 9px !important;
}
.liveclass_details .applybtn .MuiButtonBase-root {
  padding: 0 !important;
}
.topic
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label {
  background-color: #1875f0 !important;
  color: white !important;
}
/* .topic .rpv-core__viewer {
    width: 85% !important;
    margin: auto !important;
} */
.classes_css .MuiButton-root:hover {
  background-color: white !important;
}

.classes_css .MuiToggleButton-root {
  padding: 6px !important;
  border-radius: 4% !important;
}
.common_classes .custbtngp1 .MuiToggleButton-root {
  padding: 6px !important;
}
.class_wise .MuiToggleButtonGroup-root > .Mui-selected {
  background-color: #1875f0 !important;
  color: white !important;
}
.class_wise .MuiToggleButton-root {
  padding: 6px !important;
}
.btncustcolor li:nth-child(1) .topbarcustombtn {
  border: 1px solid #f3487c;
  color: #f3487c;
}
.btncustcolor li:nth-child(2) .topbarcustombtn {
  border: 1px solid #ffab15;
  color: #ffab15;
}
.btncustcolor li:nth-child(3) .topbarcustombtn {
  border: 1px solid #3ad47b;
  color: #3ad47b;
}
.btncustcolor li:nth-child(4) .topbarcustombtn {
  border: 1px solid #7078e7;
  color: #7078e7;
}
.btncustcolor li:nth-child(5) .topbarcustombtn {
  border: 1px solid #2ec5df;
  color: #2ec5df;
}
.btncustcolor li:nth-child(6) .topbarcustombtn {
  border: 1px solid #f3487c;
  color: #f3487c;
}
.btncustcolor li:nth-child(7) .topbarcustombtn {
  border: 1px solid #ffab15;
  color: #ffab15;
}
.btncustcolor li:nth-child(8) .topbarcustombtn {
  border: 1px solid #3ad47b;
  color: #3ad47b;
}
.btncustcolor li:nth-child(9) .topbarcustombtn {
  border: 1px solid #7078e7;
  color: #7078e7;
}
.btncustcolor li:nth-child(10) .topbarcustombtn {
  border: 1px solid #2ec5df;
  color: #2ec5df;
}
.btncustcolor li:nth-child(11) .topbarcustombtn {
  border: 1px solid #f3487c;
  color: #f3487c;
}
.btncustcolor li:nth-child(12) .topbarcustombtn {
  border: 1px solid #ffab15;
  color: #ffab15;
}
.btncustcolor li:nth-child(13) .topbarcustombtn {
  border: 1px solid #3ad47b;
  color: #3ad47b;
}
.btncustcolor li:nth-child(14) .topbarcustombtn {
  border: 1px solid #7078e7;
  color: #7078e7;
}
/* .btncustcolor li button{ color: white !important;} */

.btncustcolor li:nth-child(1) .activebtn {
  border: 1px solid #f3487c;
  background-color: #f3487c;
  color: white !important;
}
.btncustcolor li:nth-child(2) .activebtn {
  border: 1px solid #ffab15;
  background-color: #ffab15;
  color: white !important;
}
.btncustcolor li:nth-child(3) .activebtn {
  border: 1px solid #3ad47b;
  background-color: #3ad47b;
  color: white !important;
}
.btncustcolor li:nth-child(4) .activebtn {
  border: 1px solid #7078e7;
  background-color: #7078e7;
  color: white !important;
}
.btncustcolor li:nth-child(5) .activebtn {
  border: 1px solid #2ec5df;
  background-color: #2ec5df;
  color: white !important;
}
.btncustcolor li:nth-child(6) .activebtn {
  border: 1px solid #f3487c;
  background-color: #f3487c;
  color: white !important;
}
.btncustcolor li:nth-child(7) .activebtn {
  border: 1px solid #ffab15;
  background-color: #ffab15;
  color: white !important;
}
.btncustcolor li:nth-child(8) .activebtn {
  border: 1px solid #3ad47b;
  background-color: #3ad47b;
  color: white !important;
}
.btncustcolor li:nth-child(9) .activebtn {
  border: 1px solid #7078e7;
  background-color: #7078e7;
  color: white !important;
}
.btncustcolor li:nth-child(10) .activebtn {
  border: 1px solid #2ec5df;
  background-color: #2ec5df;
  color: white !important;
}
.btncustcolor li:nth-child(11) .activebtn {
  border: 1px solid #f3487c;
  background-color: #f3487c;
  color: white !important;
}
.btncustcolor li:nth-child(12) .activebtn {
  border: 1px solid #ffab15;
  background-color: #ffab15;
  color: white !important;
}
.btncustcolor li:nth-child(13) .activebtn {
  border: 1px solid #3ad47b;
  background-color: #3ad47b;
  color: white !important;
}
.btncustcolor li:nth-child(14) .activebtn {
  border: 1px solid #7078e7;
  background-color: #7078e7;
  color: white !important;
}
.common_classes .MuiAppBar-colorDefault {
  color: var(--blue);
  background-color: transparent !important;
}
.common_classes .MuiPaper-elevation4 {
  box-shadow: none !important;
}
.common_classes .MuiTab-root {
  min-width: 100px !important;
}
.common_classes .cmnclasscust .MuiTab-root {
  padding: 0% !important;
}
.common_classes .MuiTab-labelIcon {
  min-height: 50px !important;
}
.common_classes .cmnclasscust .MuiBox-root {
  padding: 0px !important;
}
.common_classes li {
  margin-right: 0.5% !important;
}
.common_classes .btncustcolor .MuiButtonBase-root {
  min-width: 100px !important;
}
.common_classes .btncustcolor .arrowbtns {
  min-width: 35px !important;
}
.common_classes .MuiToggleButton-root {
  border-radius: 5px !important;
}
.common_classes .btncustcolor li {
  border-radius: 5px !important;
}
.common_classes .btncustcolor .MuiButton-root {
  font-size: 12px !important;
  text-transform: none !important;
  min-height: 35px !important;
}
.class_wise .bagdecss {
  min-width: 40px !important;
}
.class_wise .btncst {
  border-radius: 20px !important;
  padding: 5px 30px !important;
}
.class_wise .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.btncustcolor li button {
  line-height: 0.6 !important;
}

.common_classes .arrowbtns {
  min-width: 35px !important;
  padding: 0px !important;
  background-color: white !important;
  color: grey !important;
}
.common_classes .btncustcolor1 .arrowbtns {
  min-width: 35px !important;
  min-height: 35px !important;
}
.common_classes .MuiTab-wrapper {
  display: block !important;
}
.common_classes .MuiTab-labelIcon .MuiTab-wrapper > :first-child {
  margin-right: 6px !important;
  margin-bottom: 0px !important;
}
.topic .btncustcolor1 .MuiButtonBase-root {
  /* min-width: 150px !important; */
  width: max-content !important;
  min-height: 40px !important;
}
.topic .btncustcolor2 .MuiButtonBase-root {
  min-height: 35px !important;
}

.topic .arrowbtns {
  min-width: 35px !important;
  padding: 0px !important;
  background-color: white !important;
  color: grey !important;
}
.topic .btncustcolor1 .arrowbtns {
  min-width: 35px !important;
}
.topic .MuiTab-wrapper {
  display: block !important;
}
.topic .MuiTab-labelIcon .MuiTab-wrapper > :first-child {
  margin-right: 6px !important;
  margin-bottom: 0px !important;
}
.topic .MuiAppBar-colorDefault {
  color: var(--blue);
  background-color: transparent !important;
}
.topic .MuiPaper-elevation4 {
  box-shadow: none !important;
}
.topic .MuiTab-root {
  min-width: 125px !important;
}
.topic .cmnclasscust .MuiTab-root {
  padding: 0% !important;
}
.topic .MuiTab-labelIcon {
  min-height: 50px !important;
}
.topic .cmnclasscust .MuiBox-root {
  padding: 0px !important;
}
.topic li {
  margin-right: 0.5% !important;
}

.topic .MuiToggleButton-root {
  border-radius: 5px !important;
}
.topic .btncustcolor1 li {
  border-radius: 5px !important;
}
.topic .btncustcolor1 .MuiButton-root {
  font-size: 12px !important;
  text-transform: none !important;
}
.topic .MuiToggleButtonGroup-root > .Mui-selected {
  background-color: #1875f0 !important;
  color: white !important;
}
.topic .MuiToggleButton-root {
  padding: 6px !important;
}
.topic .btncustcolor1 li button {
  line-height: 1 !important;
}
.cmnclasscust {
  margin-left: -4% !important;
}
.byTopics .MuiToggleButton-root {
  border-radius: 5px !important;
}
.byTopics .MuiToggleButtonGroup-root > .Mui-selected {
  background-color: #1875f0 !important;
  color: white !important;
}
.byTopics .MuiToggleButton-root {
  padding: 6px !important;
}
.byTopics .bagdecss {
  min-width: 110px !important;
}
.notification .notifimsg {
  font-size: 14px !important;
  color: grey !important;
}
.notification .closebtncenter {
  position: relative;
  top: 20%;
  background: unset !important;
}
.notification .MuiPagination-root {
  /* margin: 0% 38% !important; */
}
.notification .MuiPaginationItem-outlinedPrimary.Mui-selected {
  background-color: var(--blue) !important;
  color: white !important;
  border: 1px solid !important;
}
.customroundSelect .MuiOutlinedInput-root {
  border-radius: 20px !important;
}
.supportticket .notifimsg1 {
  font-size: 14px !important;
  color: grey !important;
  padding-bottom: 2% !important;
}
/* .supportticket .MuiPagination-root{
    margin: 0% 38% !important;
} */
.supportticket .MuiPaginationItem-outlinedPrimary.Mui-selected {
  background-color: var(--blue) !important;
  color: white !important;
  border: 1px solid !important;
}
.supportticket .my-anchor-css-class {
  color: #4ac3e0 !important;
}
.dashboard .MuiToggleButtonGroup-root > .Mui-selected {
  background-color: #1875f0 !important;
  color: white !important;
}
.dashboard .MuiToggleButton-root {
  padding: 7.5px !important;
  border-radius: 20px !important;
}
.dashboard .MuiToggleButton-label {
  text-transform: none !important;
}
.dashboard .MuiOutlinedInput-notchedOutline {
  border-radius: 20px !important;
}
.dashboard .dashbtn {
  width: auto !important;
  min-width: 110px !important;
}

.dashboard .MuiOutlinedInput-input {
  text-align: left !important;
}
.dashboard .font-cust2,
.instructor_payments .font-cust2 {
  font-size: 13px !important;
  color: grey !important;
  margin: 5px;
}
.dashboard .font-cust1,
.instructor_payments .font-cust1 {
  font-size: 20px !important;
  font-weight: 600 !important;
  position: relative;
  margin: 5px;
}
.dashboard .custom-box1,
.instructor_payments .custom-box1 {
  border-radius: 10px !important;
  margin: 0% 1% !important;
  width: 120px !important;
  padding-bottom: 40px;
}
.dashboard .font-cust3 {
  font-size: 13px !important;
  color: white !important;
}
.dashboard .font-cust4 {
  font-weight: 600;
}
.dashboard .progressbar-progress {
  height: 5px !important;
  border-radius: 20px !important;
}
.prgbar1 .progressbar-progress {
  background-color: #e8aa43 !important;
  margin-bottom: 2% !important;
}
.prgbar2 .progressbar-progress {
  background-color: #ef526d !important;
  margin-bottom: 2% !important;
}
.prgbar3 .progressbar-progress {
  background-color: #7cbaf3 !important;
  margin-bottom: 2% !important;
}
.prgbar4 .progressbar-progress {
  background-color: #03c68a !important;
  margin-bottom: 2% !important;
}

.dashboard .custom-font1 {
  color: grey;
  font-size: 14px !important;
  margin-bottom: 1% !important;
}
.dashboard .custom-font2 {
  text-align: right !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: grey !important;
}

.dashboard th {
  font-weight: 600;
}
.dashboard .MuiTableRow-head {
  background-color: #f8f8f8 !important;
}
.dashboard .MuiLink-root {
  color: #3aabd3 !important;
}
.dashboard .MuiTableContainer-root {
  box-shadow: unset !important;
}
.dashboard .cstom-btn2 {
  font-size: 12px !important;
  text-transform: none !important;
}
.dashboard .cust-select .MuiOutlinedInput-inputMarginDense {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}
.report .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.report .custom-card1 .MuiCardContent-root:last-child {
  padding: 30px 30px !important;
  padding-bottom: 60px !important;
}
.report .MuiInput-underline::before {
  content: none !important;
}
.report .MuiInput-underline::after {
  content: none !important;
}
.report .MuiInput-formControl {
  border: 1px solid #c4c4c4 !important;
  border-radius: 20px !important;
  color: #c4c4c4 !important;
  min-width: 170px !important;
  padding: 2% !important;
}
.report .cstbtn2 {
  text-transform: none !important;
  width: 100px !important;
}
.report .custom-card1 .MuiTypography-body1 {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.report .MuiRadio-colorPrimary.Mui-checked {
  color: #1f7bc8 !important;
}
.report .cust-label .MuiFormLabel-root {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.dashboard .triangle-mark {
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  border-radius: 0;
  width: 8px;
  height: 8px;
  position: relative;
  top: -2px;
  right: 1px;
}

.dashboard .square-mark {
  border-radius: 0;
}
.dashboard .custom-table1 td {
  width: 100px;
  text-align: center;
  padding: 8px 0px !important;
  border-radius: 5px;
  font-size: 14px;
}
.dashboard .custom-table1 th {
  width: 100px;
  text-align: center !important;
  color: #313131 !important;
  font-weight: 500 !important;
  padding: 8px 0px !important;
  font-size: 14px;
}
/* .dashboard .highlight,
.student_dashbaord .highlight {
  background-color: #03c68a;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
} */
.dashboard .highlight,
.student_dashbaord .highlight {
  /* background-image: radial-gradient(50px circle at 100px 100px, */
  /* #FFFFFF 50%, */
  /* transparent 51%), */
  /* background-image: linear-gradient(45deg, white 90%, red 10%); */
  background-color: #b9d7ff !important;
  border: 1px solid white;
  border-radius: 5px;
}
.student_dashbaord .customtablesd .react-calendar__tile--active {
  background: #00ce4c;
  color: white;
}
.dashboard .highlight:hover,
.student_dashbaord .highlight:hover {
  background-color: #1875f0 !important;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
}
.student_dashbaord .react-calendar {
  border: none !important;
}
.student_dashbaord .customtablesd .react-calendar__navigation {
  margin-bottom: 0 !important;
}
.student_dashbaord .react-calendar__tile {
  padding: 6px !important;
}
.dashboard .react-calendar {
  background-color: #f8f8f8;
  border: none !important;
  margin-left: 4%;
  margin-top: 2%;
  width: 95%;
}
.dashboard .react-calendar__navigation {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.dashboard .react-calendar__tile--active {
  /* background: var(--blue) !important; */
  background: #00ce4c;
  color: white;
  border-radius: 5px !important;
}
.instructor-registration .custom-Grid {
  margin-top: 3% !important;
}
.instructor-registration .custom-Gridwithlabel {
  margin-top: 1% !important;
}

.custom-inputfile .form-group {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  min-height: 38px !important;
}
.custom-inputfile .form-group .form-file {
  padding: 6px 0px !important;
}
.custom-inputfile2 .form-group {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  min-height: 38px !important;
}
.custom-inputfile2 .form-group .form-file {
  padding: 54px 0px !important;
}

.custom-social-icon .MuiButton-root {
  min-width: 45px !important;
}
.custom-social-icon .active {
  color: white !important;
  border-radius: 20px !important;
  background-color: var(--blue) !important;
}
.custom-svg .MuiSvgIcon-root {
  color: var(--yellow) !important;
}
.instructor-registration .previous-btn {
  text-transform: none !important;
  color: #747474 !important;
  background-color: #f0f0f0 !important;
  border-radius: 20px !important;
}

.instructor-registration .form-file-label {
  margin-right: 4% !important;
  color: #757575 !important;
}
.topic .custom-tab .MuiAppBar-colorPrimary {
  background-color: transparent !important;
}
.topic .custom-tab .MuiTabs-indicator {
  background-color: var(--yellow) !important;
}
.topic .custom-tab .MuiTab-wrapper {
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.topic .custom-tab .MuiTab-textColorInherit {
  color: grey !important;
}
.topic .custom-tab .Mui-selected .MuiTab-wrapper {
  color: black !important;
}
.topic .custom-tab .MuiTreeItem-label {
  color: black !important;
}
.topic .custom-tab .MuiCollapse-entered .MuiTreeItem-label {
  color: grey !important;
}
.topic .custom-tab .MuiTreeItem-content .MuiTypography-body1 {
  font-size: 14px !important;
}
.topic .MuiBox-root {
  padding-left: 0px !important;
}
.topic .rs-play {
  height: 27px !important;
  width: 30px !important;
}
.topic .rs-play > svg {
  width: 25px !important;
}
.topic .rs-container {
  width: unset !important;
}
.topic .circle {
  background-color: var(--yellow);
  height: 140px;
  width: 140px;
  display: block;
  border-radius: 80px;
  margin-left: 16%;
  margin-top: 2%;
}
.custom-btn1 {
  border: 2px solid #c9c9c9 !important;
  border-radius: 20px !important;
  padding: 0px 16px !important;
}
.custom-btn1 .MuiButton-label {
  color: grey !important;
  text-transform: none !important;
}
.custom-btn1.active {
  border: 2px solid var(--yellow) !important;
}
.topic .custom-tab .MuiCard-root {
  height: 100% !important;
}
.topic .customcard2tp {
  height: 100% !important;
}
.border_bottom {
  border-bottom: 2px solid var(--yellow) !important;
}
.teach .custom-btn2 {
  margin: 10% !important;
  margin-left: 76% !important;
  margin-right: 0% !important;
  padding: 8px 35px !important;
}
.teach .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

#acco1 {
  margin-left: -43px !important;
}
.dashboard .MuiSelect-select:focus,
.notification .MuiSelect-select:focus {
  border-radius: 20px !important;
}
.student_dashbaord .MuiSelect-select:focus,
.subscription .MuiSelect-select:focus,
.supportticket .MuiSelect-select:focus,
.report .MuiSelect-select:focus {
  border-radius: 20px !important;
}
.student_dashbaord .customtablesd tr {
  line-height: 1.8 !important;
}
.student_dashbaord .customtablesd td {
  color: gray !important;
}
.common_classes .customcmngrid .MuiBox-root {
  padding: 5px 80px 5px 0px !important;
}
.common_classes .custombarbtn {
  width: max-content !important;
}
.topic .customdivtopic canvas {
  max-height: 280px;
  width: 250px !important;
}
.custompopup .MuiFormHelperText-marginDense,
.custompopup .MuiFormHelperText-root {
  margin-top: 0px !important;
  font-size: 10px !important;
}
.custompopup .alert {
  margin-bottom: 0px !important;
  font-size: 12px !important;
}
.instructor_payments .cstcardinst_pay {
  border-radius: 20px !important;
  background: #0069c9;
  color: white;
}
.parent_payments .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.parent_payments .MuiAppBar-positionStatic {
  box-shadow: none !important;
  background-color: none !important;
}
@media only screen and (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 100% !important;
  }
}
.byTopics .btncst,
.topic .btncst {
  border-radius: 20px !important;
  padding: 5px 30px !important;
}

.profile #customized-dialog-title .MuiTypography-root {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: white !important;
}
.student_dashbaord .MuiToggleButtonGroup-root > .Mui-selected {
  background-color: #1875f0 !important;
  color: white !important;
}
.student_dashbaord .MuiToggleButton-root {
  padding: 6px !important;
}
.student_dashbaord .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.topic .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.student_dashbaord .customchartsd .canvasjs-chart-canvas {
  height: 246px !important;
  width: 308px !important;
}
.student_dashbaord .customchartsd2 .canvasjs-chart-canvas {
  height: 246px !important;
  width: 740px !important;
}
.student_dashbaord .custcirclest {
  border: 2px dotted #3f8bf1;
  font-size: 40px;
  padding: 50px;
  border-radius: 50%;
  margin: 40px 30px 20px 30px;
  color: #005edb;
}
.student_dashbaord .customselectsd .MuiOutlinedInput-root {
  border-radius: 20px !important;
  font-size: 12px !important;
}
.student_dashbaord .customselectsd2 .MuiOutlinedInput-root {
  font-size: 15px !important;
}
.student_dashbaord .customselectsd2 .MuiSelect-root {
  padding-left: 0 !important;
}
.student_dashbaord .customselectsd2 .MuiSelect-select:focus {
  background-color: white;
}
.student_dashbaord .customselectsd2 .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.student_dashbaord .custcontst1 {
  border: 1px solid;
  border-radius: 20px;
  padding: 4px 8px;
}
.student_dashbaord .custcontst2 {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 4px 8px;
}
.customgridxs4tp {
  max-width: 26% !important;
}
@media only screen and (max-width: 1350px) {
  .subscription .customgridsccust {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}
@media only screen and (max-width: 1200px) {
  .common_classes .customgridxs2cmn {
    padding-right: 18px !important;
  }
  .subscription .customgridsccust2 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .subscription .customgridsccust2 .customcardsc9 {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 1180px) {
  .instructor_payments .customdashcardip {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .profile .custompprofiletext {
    padding-left: 0% !important;
  }
  .subscription .customgp2 {
    width: 150px !important;
  }
  .subscription .freetrailcs {
    top: -10px !important;
    left: -26px !important;
  }
  .notification .MuiPagination-root {
    margin: 0% !important;
  }
  .supportticket .MuiPagination-root {
    margin: 0% !important;
  }
  .topic .custom_containertp {
    padding: 0.5% 2% !important;
  }
  .topic .custom_containertp1 {
    padding: 0.5% 105px 0.5% 2% !important;
  }
  .customgridxs4tp {
    max-width: 10% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .class_wise {
    padding: 1% 2% !important;
  }
  .class_wise .MuiToggleButton-root {
    width: 134px !important;
  }
  .byTopics .MuiToggleButton-root {
    width: 134px !important;
  }
  .subheader .MuiCard-root {
    width: 150px !important;
    margin: 1% 0% !important;
  }
  .subscription .customgp1 {
    width: 170px !important;
  }
  .subscription .customgp2 {
    width: 132px !important;
  }
  .dashboard .cust_select_ds3 {
    width: 25% !important;
  }
  .student_dashbaord .custom_containersd {
    padding: 0.5% 2% !important;
  }
}
@media only screen and (max-width: 999px) {
  /* .profile .custom-card1{
    margin-top: 43px !important;
   } */
  .liveclass .customdivlc {
    padding: 0px 0px !important;
  }
  .recordedclass .customdivrc {
    padding: 0px 0px !important;
  }
  .dashboard .customselectds4 {
    width: 95% !important;
  }
  .topic .customgridxs10tp {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .topic .cmnclasscust {
    margin-left: 0% !important;
  }
  .topic .customgridxs8tp {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .topic .customgridxs8tp .MuiToggleButtonGroup-root {
    display: block !important;
  }
  .topic .customgridxs8tp .MuiToggleButton-root {
    margin: 2% auto !important;
    width: 220px !important;
  }
  .instructor-registration {
    padding: 0.5% 2% !important;
  }
}
@media only screen and (max-width: 960px) {
  .classes_css .MuiGrid-container {
    padding: 2% 4% !important;
  }
  .common_classes .cmnclasscust {
    margin-left: 0% !important;
  }
  /* .common_classes .cmnclasscust { padding-right: 100px !important;} */
}
@media only screen and (max-width: 959px) {
  .parent_payments,
  .instructor_payments {
    padding: 0 20px !important;
  }
  .instructor_payments .customcardinst_pay {
    padding-left: 0% !important;
    padding-top: 10px !important;
  }
  .student_dashbaord .cstcardst {
    padding-top: 20px !important;
  }
  .student_dashbaord .customimgst {
    margin-top: 18px !important;
  }
  .student_dashbaord .cstcardst2 {
    padding-left: 0 !important;
  }
  .student_dashbaord .commonboxst {
    padding-top: 10px !important;
  }
  .student_dashbaord .boxcustomst {
    padding-right: 0px !important;
  }
  .registrationModal .customformcontrol1 {
    width: 100% !important;
  }
  .footer .customgridft {
    padding-left: 25px !important;
  }
  .student_dashbaord .customtablesd {
    padding-left: 0px !important;
  }
  .liveclass_details .customprmld {
    padding-right: 0px !important;
  }
  .student_dashbaord .custom_containersd {
    padding: 0.5% 3% !important;
  }
  .cmn-plan .customcardpr {
    margin-right: 0% !important;
  }
  .report .cstbtn2 {
    margin-top: 2.5% !important;
  }
  .topic .customgridtopic {
    padding: 0px 65px 0px 15px !important;
  }
  .topic .customrgtarrwbtn {
    right: 15px !important;
  }
  .common_classes .customgridxs2cmn {
    text-align: left !important;
  }
  .topic .customgridxs9tp {
    padding-left: 0 !important;
  }
  .topic .customgridxs2tp {
    padding-top: 15px !important;
  }
  .profile .customcardpf {
    height: 98% !important;
  }
  .liveclass .custompaddinglc {
    padding-top: 20px !important;
  }
  .subscription .customcontainersc {
    margin: 10px 0 !important;
  }
  .subscription .customsummarysc {
    margin-top: 10px !important;
  }

  .class_wise .customcardclass {
    margin: 2% 0% !important;
  }
  .classes_css .customcardbs {
    margin: 2% 0% !important;
  }
  .byTopics .customcardbtp {
    margin: 2% !important;
  }
  .common_classes .customcmngrid .MuiBox-root {
    padding: 5px 65px 5px 20px !important;
  }
  .liveclass .customSp {
    width: 84% !important;
    margin: 10px !important;
  }
  .customgridlc1 {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .common_classes .rightarrowbtncmn,
  .common_classes .cstarrowbtn {
    right: 16px !important;
  }
}
@media only screen and (max-width: 950px) {
  .cmn-plan .custom-font {
    font-size: 14px !important;
  }
  .dashboard .customcontainerds {
    padding: 0% 2% !important;
  }
}
@media only screen and (max-width: 920px) {
  .common_classes .custom_grid_cmn {
    padding: 0.5% 10px !important;
  }
  .common_classes .customgridxs2cmn {
    padding-right: 0px !important;
    padding: 10px 0 0 30px !important;
  }
  .common_classes .custbtngp1 {
    margin-left: 4% !important;
    padding-right: 0px !important;
  }
  .subheader .MuiCard-root {
    max-width: 120px !important;
  }
  .subscription .custgridsc4 {
    max-width: 170px !important;
    flex-basis: 170px !important;
  }
  .subscription .cust_age {
    max-width: 20% !important;
    flex-basis: 20% !important;
  }
}

@media only screen and (max-width: 870px) {
  #acco1 {
    margin-left: 0px !important;
  }
  .classes_css .MuiGrid-container {
    padding: 2% 4% !important;
  }
  .common_classes .MuiToggleButton-root {
    width: 135px !important;
  }
  .class_wise .MuiToggleButton-root,
  .byTopics .MuiToggleButton-root {
    width: 124px !important;
  }
  .class_wise .custbtngrp2,
  .byTopics .custbtngrp2 {
    text-align: center !important;
  }
  .dashboard .cust_select_ds3 {
    width: 30% !important;
  }
}

@media only screen and (max-width: 842px) {
  .cmn-plan .custom-font {
    font-size: 12px !important;
  }
  .subscription .cust_age {
    max-width: 22% !important;
    flex-basis: 22% !important;
  }
  .common_classes .custom_grid_cmn {
    padding-left: 20px !important;
  }
  .byTopics .customcontainerbt {
    padding: 0.5% 20.5px !important;
  }
  .byTopics .custom_topic_container {
    padding: 0.5% 10px !important;
  }
}

@media only screen and (max-width: 820px) {
  .profile .custom-grid {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .cmn-plan .csttxt {
    font-size: 16px !important;
  }
  .subheader .MuiCard-root {
    width: 120px !important;
  }
}

@media only screen and (max-width: 800px) {
  .liveclass .customSp {
    width: 97% !important;
  }
  .common_classes .customcmngrid .MuiBox-root {
    padding: 5px 65px 5px 25px !important;
  }
  .common_classes .customcardbs {
    margin: 20px 0px !important;
  }
  .teach .custom-btn2 {
    margin-left: unset !important;
  }
  .privacy {
    margin: unset !important;
  }
  .terms {
    margin: unset !important;
  }
  .teach > .MuiGrid-container {
    padding: 0.5% 2% !important;
  }
  .teach .MuiGrid-grid-xs-7,
  .teach .MuiGrid-grid-xs-5 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  /* .teach .cardimg{ width: unset !important;} */
  .teach .MuiCard-root {
    height: unset !important;
  }
  .subscription .custgridsc4 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .subscription .custgridsc {
    padding-bottom: 2% !important;
  }
  .subscription .custbtngp {
    padding-left: 10px !important;
  }
  .subscription .custom-containersc {
    padding: 0% 2% !important;
  }
  .calender .custom-containercl {
    padding: 0% 2% !important;
  }
  .profile {
    padding: 0% 2% !important;
  }
  .report .custom-containerrp {
    padding: 0% 2% !important;
  }
  .supportticket .custom-containersc {
    padding: 0% 2% !important;
  }
  .notification .custom-containernf {
    padding: 0% 2% !important;
  }

  .dashboard .cust-select {
    text-align: right !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .customgrid2ds1 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .customgrid2ds1 .MuiTypography-h4 {
    text-align: center !important;
  }
  .dashboard .customselectds4 {
    width: 50% !important;
  }
  .dashboard .customgrid6ds,
  .instructor_payments .customgrid6ds {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-bottom: 20px !important;
  }
  .dashboard .customtableds {
    max-width: 100% !important;
    margin-bottom: 20px !important;
  }
  .dashboard .customgridxs8ds {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-bottom: 20px !important;
  }
  .dashboard .customselectds4 {
    width: 35% !important;
  }
  .dashboard .customgridxs4ds {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-bottom: 20px !important;
  }
  .dashboard .customtableds {
    width: 100% !important;
  }
  .notification .customgridxsnf {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: center !important;
  }
  .supportticket .customgridst {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: center !important;
  }
  .report .customgridrp {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: center !important;
  }
  .profile .custom-gridpf {
    text-align: center !important;
    margin-top: 10px !important;
  }
  .calender .customgridcl {
    text-align: center !important;
    margin-bottom: 10px !important;
  }
  .customgridlc1 {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .customgridlc2 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: left !important;
  }
  .customgridlc3 > .MuiFormControl-root {
    margin: 0px !important;
    width: 97% !important;
  }
  .customgridlc4 > .MuiFormControl-root {
    margin: 0px !important;
    width: 97% !important;
  }
  .customgridlc5 > .MuiFormControl-root {
    margin: 0px !important;
    width: 97% !important;
  }
  .customgridlc3,
  .customgridlc4,
  .customgridlc5 {
    max-width: 33% !important;
    flex-basis: 33% !important;
    text-align: left;
  }
  .student_dashbaord .customgridxs10sd {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .student_dashbaord .customgridxs10sd > .MuiToggleButtonGroup-root {
    display: block !important;
  }
  .student_dashbaord .customgridxs10sd .MuiToggleButton-root {
    margin: 2% auto !important;
    width: 220px !important;
  }
  .student_dashbaord .customgridxs7sd,
  .student_dashbaord .customgridxs5sd {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .topic .customtexttp {
    margin-top: -4% !important;
  }
  .topic .leftarrwbtntp {
    margin-left: -43px !important;
  }
  .topic .custom-tab,
  .topic .customgridxs9tp {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .topic .customgridxs9tp {
    padding-left: 0% !important;
  }
  .topic .customdivtp1,
  .topic .customdivtp2 {
    width: 30% !important;
  }
  .topic .customgridxs4tp {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .topic .custom_containertp1 {
    padding: 0.5% 60px 0.5% 2% !important;
  }
  .topic .customrgtarrwbtn {
    right: 18px !important;
  }
  .topic .customgridxs2tp {
    max-width: 30% !important;
    flex-basis: 30% !important;
  }
  .common_classes .customgridcmn2 {
    padding-right: 0% !important;
  }
  .common_classes .rightarrowbtncmn {
    right: 20px !important;
  }
  .common_classes .topic {
    padding-right: 0% !important;
  }
  .common_classes .cstarrowbtn {
    right: 20px !important;
  }
  .topic .customgridxs10tp {
    padding-right: 0% !important;
  }
}

@media only screen and (max-width: 770px) {
  .common_classes .custbtngp1 {
    margin-left: 0% !important;
  }
  .common_classes .custbtngp1 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: center !important;
    margin: 10px 0px !important;
  }
  .classes_css .MuiGrid-grid-xs-10 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .common_classes .MuiTabs-scroller {
    margin-left: 40px !important;
  }
  .class_wise .custbtngrp2,
  .byTopics .custbtngrp2 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .subscription .cust_age {
    max-width: 24% !important;
    flex-basis: 24% !important;
  }
}
@media only screen and (max-width: 750px) {
  .cmn-plan .cardpr > .MuiCard-root {
    margin-right: 0% !important;
  }
  .cmn-plan .cardpr,
  .cmn-plan .cardpr1 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .cmn-plan .csttxt {
    font-size: 18px !important;
  }
  .cmn-plan .custom-font {
    font-size: 16px !important;
  }
  .subheader .MuiCard-root {
    width: 108px !important;
  }
  .common_classes .topic {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .subscription .customgrid4sc2 {
    max-width: 48% !important;
    flex-basis: 48% !important;
  }
  .subscription .customfontsc {
    font-size: 20px !important;
    text-align: center !important;
  }
  .subscription .cust_age {
    max-width: 28% !important;
    flex-basis: 28% !important;
  }
  .dashboard .customgridds2 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .customgridds3 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .dashboard .dsbtn2 {
    margin: 2% 0% 0% 2% !important;
  }
  .dashboard .customgridds4 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-top: 2% !important;
    text-align: center !important;
  }
  .dashboard .customgridds5 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .react-calendar {
    width: 100% !important;
    margin-top: 3% !important;
    margin-left: 0% !important;
  }
  .dashboard .customgrid6d6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .customgrid6d7 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-top: 2% !important;
  }
  .dashboard .customtable2 {
    width: 100% !important;
  }
  .dashboard .customgridd6 {
    max-width: 30% !important;
    flex-basis: 30% !important;
  }
  .dashboard .grid-container {
    justify-content: center !important;
  }
  .dashboard .custom-divds {
    display: flex !important;
    flex-direction: column;
    height: 93% !important;
  }
  .inst_lifetimeGrid > .customgridds2 {
    flex-direction: column;
  }
  .dashboard .customgridds2 .custom-box1,
  .instructor_payments .customgridds2 .custom-box1 {
    margin: 2% auto !important;
    width: 50% !important;
  }
  .dashboard .customgridds7 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .custombgds {
    height: unset !important;
    margin-bottom: 2% !important;
  }
  .dashboard .custombgds .MuiTypography-root {
    padding: 0% !important;
  }
  .topic .customdivtp1,
  .topic .customdivtp2 {
    width: 45% !important;
  }
}

@media only screen and (max-width: 670px) {
  .common_classes .customgridcmn2 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .common_classes .makeStyles-custom2-11 {
    width: 110px;
  }
  .common_classes .custbtngp2 {
    text-align: center !important;
  }
  .common_classes .custbtngp2 .MuiToggleButton-root {
    width: 100px !important;
  }
  .class_wise .MuiToggleButton-root,
  .byTopics .MuiToggleButton-root {
    width: 100px !important;
  }
  .subheader .MuiCard-root {
    width: 140px !important;
    margin: 2% 0% !important;
  }
  .notification .closebtncenter {
    top: 54px !important;
    right: 10px !important;
  }
  .byTopics .custbtngrp2 > .MuiToggleButtonGroup-root {
    display: block !important;
  }
  .byTopics .custbtngrp2 .MuiToggleButton-root {
    width: 230px !important;
    margin: 10px auto !important;
  }
  .byTopics .customgridxs6bt {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .customgridd7 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .dashboard .customgridd7 .MuiToggleButtonGroup-root {
    display: flex !important;
  }
  .dashboard .customgridd7 .MuiButtonBase-root {
    min-width: 180px !important;
    margin: 1.5% auto !important;
  }
  .dashboard .customgridd6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: center !important;
  }
  .dashboard .customgridd6 .MuiFormControl-root {
    width: 20% !important;
  }

  .instructor-registration .MuiBox-root {
    padding: 5px !important;
  }
  .instructor-registration .Muicust {
    width: 100% !important;
  }
  .instructor-registration .custom-Grid .MuiFormControl-root,
  .instructor-registration .custom-Gridwithlabel .MuiFormControl-root {
    width: 100% !important;
  }
  .instructor-registration .MuiGrid-grid-xs-12 {
    margin-top: 3% !important;
    margin-bottom: 2% !important;
  }
}
.inst_lifetimeGrid {
  display: flex;
}
@media only screen and (max-width: 650px) {
  .subscription .custbtngp .MuiToggleButtonGroup-root {
    display: block !important;
    padding: 0px 100px !important;
  }
  .subscription .customgp2 {
    width: 200px !important;
  }
  .subscription .MuiToggleButton-root {
    margin: 5px 0px !important;
  }
  .subscription .cust_age {
    max-width: 32% !important;
    flex-basis: 32% !important;
  }
  .dashboard .custom-table1 th {
    font-size: 12px !important;
  }
  .recordedclass .search-form {
    width: 70% !important;
  }
  .topic .customgridxs9tp .MuiTypography-h6 {
    font-size: 16px !important;
  }
  .topic .customgridxs9tp .MuiCardContent-root {
    padding: 5px !important;
  }
  .topic .customimgtp {
    height: 100px !important;
  }
  .topic .customgridxs8tp1 {
    max-width: 80% !important;
    flex-basis: 80% !important;
  }
  .inst_lifetimeGrid {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: space-between;
    align-items: center; */
  }
  .inst_lifetimeGrid > .customgridds7 {
    height: none !important;
  }
}
@media only screen and (max-width: 599px) {
  .instructor_payments .custom-box1 {
    margin: 2% 1% !important;
    width: 96% !important;
    min-height: 70px !important;
  }
  .instructor_payments .custom-mainbox1 {
    height: 80% !important;
  }
  .instructor_payments .custom-mainbox1 {
    width: 98% !important;
    display: block !important;
    height: 100%;
  }
  .instructor_payments .custom-mainbox1 {
    padding: 10% 2% !important;
  }
  .instructor_payments .customcardinst_pay2 {
    padding-top: 10px !important;
    padding-left: 0px !important;
  }
  .student_dashbaord .commonboxst,
  .student_dashbaord .customboxst2,
  .student_dashbaord .cstcardst5 {
    padding-right: 0px !important;
  }
  .student_dashbaord .customgridds,
  .student_dashbaord .customtablesd {
    padding-left: 0px !important;
  }
  .student_dashbaord .cstcardst3 {
    padding-left: 0 !important;
    padding-top: 10px !important;
  }
  .student_dashbaord .cstcardst4 {
    padding-top: 10px !important;
  }
}
@media only screen and (max-width: 560px) {
  .common_classes .custbtngp1 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin: 10px 0px !important;
  }
  .byTopics .custbtngrp2 .MuiToggleButton-root {
    margin: 10px auto !important;
  }
  .subscription .cust_age {
    max-width: 35% !important;
    flex-basis: 35% !important;
  }
  .subscription .customgrid4sc2 {
    max-width: 55% !important;
    flex-basis: 55% !important;
  }
}

@media only screen and (max-width: 520px) {
  .common_classes .customcmngrid .MuiBox-root {
    padding: 5px 103px 5px 10px !important;
  }
  .calender .custom-gridxs6cl {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .calender .fc .fc-toolbar {
    display: block !important;
  }
}
@media only screen and (max-width: 510px) {
  .report .custom-group {
    display: block !important;
  }
  .notification .closebtncenter {
    top: 60px !important;
    right: 14px !important;
  }
  .common_classes .custom_grid_cmn {
    padding: 0.5% 50.5px !important;
    padding-left: 20px !important;
  }
  .customgridlc1 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .common_classes .customgridcmn2 .arrowbtns {
    right: 12px !important;
  }
  .common_classes .custom_grid_cmn .cstarrowbtn {
    right: 12px !important;
  }
  .common_classes .class_wise .custbtngrp2 > .MuiToggleButtonGroup-root {
    display: block !important;
  }
  .common_classes .class_wise .custbtngrp2 .MuiToggleButton-root {
    width: 230px !important;
    margin: 10px 80px !important;
  }
  .class_wise {
    padding: 1% 4% !important;
  }
  .class_wise .customgridxs6bycls {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .subscription .cust_age {
    max-width: 45% !important;
    flex-basis: 45% !important;
    margin: 10px 0px !important;
  }
  .subscription .deleteicon {
    margin: 10px 0px !important;
  }
  .subscription .customgrid4sc2 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .subscription .customfontsc {
    font-size: 16px !important;
  }
  .subscription .custbtngp .MuiToggleButtonGroup-root {
    padding: 0px 60px !important;
  }
  .dashboard .customgridds4 {
    margin: 20px 0% !important;
  }
}
@media only screen and (max-width: 500px) {
  .dashboard .cust_select_ds3 {
    width: 35% !important;
  }
  .dashboard .cust_select_ds1 {
    width: 27% !important;
  }
  .dashboard .custom-box1,
  .instructor_payments .custom-box1 {
    margin: 2% 1% !important;
    width: 96% !important;
    min-height: 70px !important;
  }
  .dashboard .custom-mainbox1,
  .instructor_payments .custom-mainbox1 {
    height: 80% !important;
  }
  .dashboard .custom-mainbox1,
  .instructor_payments .custom-mainbox1 {
    width: 98% !important;
    display: block !important;
    height: 100%;
  }
  .dashboard .custom-mainbox1,
  .instructor_payments .custom-mainbox1 {
    padding: 10% 2% !important;
  }
  .customgridlc1 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: center !important;
  }
  .customgridlc1 > .MuiPaper-root {
    margin: 0 auto !important;
  }
  .customgridlc3,
  .customgridlc4,
  .customgridlc5 {
    max-width: 100% !important;
    flex-basis: 100% !important;
    text-align: left;
  }
  .common_classes .MuiTabs-scroller {
    margin-left: 0px !important;
  }
  .common_classes .customtext {
    margin-top: -4% !important;
  }
  .common_classes .leftarrbtn {
    margin-left: -43px !important;
  }
  .topic .customgridxs2tp {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .customblocklc {
    display: block !important;
  }
  .customblocklc .customblocksublc {
    margin: 2% 0% !important;
  }
  .liveclasscust .MuiTypography-h6 {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 470px) {
  .common_classes .makeStyles-custom2-11 {
    width: 74px;
  }

  /* .common_classes .MuiTabs-scroller{ margin-left: 60px !important; }   */
  .byTopics .MuiGrid-grid-xs-4 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .profile .cust-font {
    font-size: 12px !important;
  }
  .subscription .customgridxs8sc {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .subscription .customgridxs4sc {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-top: 10px !important;
    text-align: center !important;
  }
  .subscription .customfontsc {
    font-size: 18px !important;
  }
  .dashboard .cust_select_ds2 {
    width: 25% !important;
  }
}
@media only screen and (max-width: 450px) {
  .dashboard .customgridd6 > .MuiFormControl-root {
    width: 30% !important;
  }
  .dashboard .customgridds4 > .MuiFormControl-root {
    width: 40% !important;
  }
  .dashboard .custom-divds {
    height: 96% !important;
  }
}
@media only screen and (max-width: 430px) {
  .common_classes .custbtngp1 > .MuiToggleButtonGroup-root,
  .common_classes .custbtngp2 > .MuiToggleButtonGroup-root {
    display: block !important;
  }
  .common_classes .custbtngp1 .MuiToggleButton-root,
  .common_classes .custbtngp2 .MuiToggleButton-root {
    width: 230px !important;
    margin: 10px 80px !important;
  }
  .subscription .custbtngp .MuiToggleButtonGroup-root {
    padding: 0px 5px !important;
  }
  .subscription .cust_age {
    max-width: 65% !important;
    flex-basis: 65% !important;
    margin: 10px 0px !important;
  }
  .subscription .btncustom12 {
    min-width: 100px !important;
  }
  .dashboard .cust_select_ds3 {
    width: 40% !important;
  }
  .dashboard .cust_select_ds2 {
    width: 27% !important;
  }
  .dashboard .custom-mainbox1,
  .instructor_payments .custom-mainbox1 {
    height: 88% !important;
  }
}
@media only screen and (max-width: 399px) {
  .common_classes .custbtngp1 .MuiToggleButton-root,
  .common_classes .custbtngp2 .MuiToggleButton-root {
    margin: 10px 50px !important;
  }
  .common_classes .class_wise .custbtngrp2 .MuiToggleButton-root,
  .common_classes .byTopics .custbtngrp2 .MuiToggleButton-root {
    margin: 10px 50px !important;
  }
  .class_wise .MuiGrid-grid-xs-4,
  .byTopics .MuiGrid-grid-xs-4 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .supportticket .custom-fontsp {
    font-size: 20px !important;
  }
  .notification .closebtncenter {
    top: 100px !important;
    right: 20px !important;
  }
  .notification .custom-fontn {
    font-size: 20px !important;
  }
}

.custbtngphover .MuiToggleButton-root:hover {
  background-color: #f8f8f8 !important;
}
.custbtngphover .Mui-selected:hover {
  background-color: var(--yellow) !important;
}
.notification .unreadmsg:hover {
  cursor: pointer !important;
}
.instructor-registration .MuiBox-root {
  padding: 24px 0px !important;
}
.instructor-registration .card-container {
  padding: 0px 5% !important;
}
.instructor-registration .MuiAppBar-root {
  box-shadow: none !important;
  background-color: transparent !important;
}

@media only screen and (min-width: 1300px) {
  .instructor-registration .MuiTab-root {
    min-width: 250px !important;
  }
}
.instructor-registration .MuiTab-root {
  border-radius: 20px !important;
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
  margin-right: 10px !important;
}
.instructor-registration .Mui-selected {
  color: #00ce4c !important;
  border-radius: 20px !important;
  border: 2px solid #00ce4c !important;
}
.instructor-registration .MuiTabs-indicator {
  background-color: transparent !important;
}
@media only screen and (max-width: 600px) {
  .instructor-registration .MuiTabs-scroller {
    display: block !important;
    white-space: initial !important;
  }
  .instructor-registration .MuiTabs-flexContainer {
    display: block !important;
  }
  .instructor-registration .MuiTab-root {
    margin: 2% !important;
  }
}
.instructor-registration .MuiStepConnector-alternativeLabel {
  top: 27px;
  left: calc(-50% + 80px) !important;
  right: calc(50% + 80px) !important;
}
.custom-istrureg {
  padding: 0px 60px !important;
}
.liveclass .search-form-cust {
  border: 1px solid #cad0d8 !important;
  border-radius: 6px !important;
  display: inline-block !important;
  background-color: white !important;
}
.customgridlc1 {
  padding: 10px !important;
  text-align: left !important;
  min-width: 20% !important;
}
.customgridlc1 .MuiCard-root {
  min-height: 310px !important;
}
.customgridlc1custom {
  padding: 10px !important;
  text-align: left !important;
  min-width: 20% !important;
}
.liveclass .customSp {
  margin: 5px 5px;
  min-width: 130px;
  float: left !important;
}
.liveclass .btn-customlc {
  padding: 4px 8px !important;
  color: #1875f0 !important;
  border: 1px solid #1875f0 !important;
  border-radius: 20px !important;
  text-transform: none !important;
  min-width: 110px !important;
  margin: 0 5px;
}
.liveclass .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}
.add_livesession .custom-Grid {
  padding: 10px !important;
  text-align: left !important;
}
.add_livesession .custom-Grid1 {
  text-align: right !important;
  margin-top: 70px !important;
}
.add_livesession .react-date-picker {
  width: 100% !important;
}
.add_livesession .react-date-picker__wrapper,
.add_livesession .react-timerange-picker__wrapper {
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  padding: 6px 0 !important;
}
@media only screen and (max-width: 1190px) {
  .liveclass .customgridlcnew1 {
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .liveclass .customgridlivebanner {
    padding: 110px 120px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .liveclass .customdivlc {
    display: block !important;
  }
  .liveclass .customdivlc .btn-customlc {
    margin: 10px !important;
  }
  .liveclass .customgridcon {
    padding: 0 20px !important;
  }
  .liveclass .customgridlivebanner {
    padding: 110px 20px !important;
  }
}
@media only screen and (max-width: 959px) {
  .custom-istrureg .Muicust,
  .custom-istrureg .Muicust1 {
    width: 100% !important;
  }

  .common_classes .custom_grid_cmn {
    padding: 0.5% 10px !important;
  }
  .class_wise .custom_class_container {
    padding: 1% 10px !important;
  }
  .subscription .customcardsc9 {
    margin-left: 0 !important;
  }
  .liveclass_details {
    padding: 0 20px !important;
  }
  .liveclass_details .customitemlcd {
    padding-top: 20px !important;
  }
  .liveclass_details .customitemlcd2 {
    padding-right: 0 !important;
  }
  .liveclass_details .customgridlcd3 {
    padding: 0 !important;
    margin: 0 !important;
  }
  .liveclass_details .customformcontrol {
    width: 100% !important;
  }
  .liveclass_details .customgridlcd2 {
    padding-top: 10px !important;
  }
  .instru_profile .customgridip2 {
    padding-right: 0 !important;
    padding-bottom: 10px !important;
  }
}
@media only screen and (max-width: 800px) {
  .add_livesession .customgridals {
    padding: 0 20px !important;
  }
  .dashboard .customdashcard1 {
    margin-right: 0 !important;
  }
  .profile .customdivpprof {
    padding-top: 10px !important;
    position: relative !important;
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 770px) {
  .instructor-registration .MuiStepConnector-root {
    display: none !important;
  }
  .instructor-registration .MuiStepper-root {
    display: block !important;
  }
  .instructor-registration .MuiStep-horizontal {
    padding: 5px !important;
  }
  .instructor-registration .custom-istrureg {
    padding: 0px 10px !important;
  }
}
.liveclass_details .custbtnld {
  min-width: 120px !important;
}
.liveclass_details .MuiInputBase-input {
  color: #757575 !important;
}
.custbtngp1 .custgpbtn1:hover {
  background-color: rgb(230, 193, 133) !important;
}
.custbtngp1 .custgpbtn2:hover {
  background-color: rgb(225, 134, 150) !important;
}
.custbtngp1 .custgpbtn3:hover {
  background-color: rgb(168, 206, 236) !important;
}
.classes_css .custbtngp2 .MuiToggleButtonGroup-root > .Mui-selected:hover {
  background-color: #0069c9 !important;
}
.custbtngrp2 .MuiToggleButtonGroup-root > .Mui-selected:hover {
  background-color: #0069c9 !important;
}
.customgridd7 .MuiToggleButtonGroup-root .MuiButtonBase-root:hover {
  background-color: #f7f7f7 !important;
}
.customgridd7 .MuiToggleButtonGroup-root > .Mui-selected:hover {
  background-color: #0069c9 !important;
}

.custombtngptopic .MuiToggleButtonGroup-root .MuiButtonBase-root:hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.custombtngptopic .MuiToggleButtonGroup-root > .Mui-selected:hover {
  background-color: #0069c9 !important;
}

@media only screen and (max-width: 449px) {
  .dashboard .dsbtn2 {
    margin: 2% 0% 0% 0% !important;
  }
  .instru_profile .customgridd7 .MuiToggleButtonGroup-root {
    display: block !important;
  }
  .instru_profile .customgridd7 .MuiToggleButtonGroup-grouped {
    width: 100% !important;
    margin: 0 auto !important;
  }
}
@media only screen and (max-width: 402px) {
  .instru_profile .custom-btn2-1 {
    margin: 5px 0 !important;
  }
}
.calender .fc-button-primary {
  background-color: #1d9ce8 !important;
}
.calender .fc-button-primary:hover {
  background-color: #4887ac !important;
}
.calender .fc-button-group > .fc-button-active {
  background-color: var(--blue) !important;
}
.calender .fc-today-button {
  background-color: var(--blue) !important;
}
.student_dashbaord .customgridxs10sd .MuiToggleButtonGroup-grouped:hover {
  background-color: rgba(106, 86, 86, 0.12) !important;
}
.student_dashbaord .MuiToggleButtonGroup-root > .Mui-selected:hover {
  background-color: #0069c9 !important;
}
.student_dashbaord .custompaper1:hover {
  background-color: #084FC7 !important;
}

.subheader .MuiLink-root > .MuiCard-root:hover {
  background-color: #ececec !important;
}
.subheader .MuiLink-root > .active:hover {
  background-color: #1875f0 !important;
}
.custom-inputfile .form-control-file {
  width: 100% !important;
}

.instru_profile .customgridip {
  text-align: left !important;
}
.instru_profile .custtitleip {
  font-weight: bold;
  color: rgb(92, 92, 92) !important;
}
.instru_profile .customgridd7 .Mui-selected {
  background-color: var(--blue) !important;
  color: white !important;
}
.instructor-registration #demo-mutiple-name {
  padding: 10px !important;
  border: 1px solid #c4c4c4 !important;
}
.instructor-registration #demo-mutiple-name:hover {
  border: 1px solid black !important;
}
.instructor-registration .cutomselect .MuiInput-underline::before {
  border-bottom: none !important;
}
.instructor-registration .cutomselect .MuiInput-underline::after {
  border: 1px solid #3f51b5 !important;
}
.calender .fc-listWeek-button {
  display: none !important;
}

.student_dashbaord .dashboardbox1:hover {
  background-color: #f0f0f0 !important;
}
.topic .topiccustombtn:hover {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}
.customappbar .MuiTab-textColorPrimary:hover {
  background-color: white !important;
}
.topbarcustombtn1 {
  text-transform: none !important;
}
.topbarcustombtn1:hover {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}
.topbarcustombtn:hover {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}
.custsetpassword .customgridsetpassword {
  padding-top: 15px !important;
}
.customcolorred .form-group {
  color: red !important;
  border-color: red !important;
}
.customcolorred #demo-mutiple-name {
  color: red !important;
  border-color: red !important;
}
.topic .customtabpanel .MuiBox-root {
  padding: 0px 0px !important;
}
.topic .MuiTabs-indicator {
  background-color: #1875f0 !important;
}
.topic .MuiTab-textColorPrimary.Mui-selected {
  color: #1875f0 !important;
}
.newcustombtngrp p {
  margin: 0% !important;
}
.liveclass_details .customgridld {
  padding-top: 10px !important;
}
.liveclass_details .MuiCardContent-root {
  padding: 16px !important;
}
@media only screen and (min-width: 960px) {
  .liveclass_details .customgridld1 {
    padding-left: 20px !important;
  }
}
.liveclass_details .customstyle {
  font-size: 17px !important;
  color: #0069c9 !important;
  font-weight: bold !important;
}
.liveclass_details .customstyle2 {
  font-size: 12px !important;
  color: #0069c9 !important;
  font-weight: bold !important;
}
.liveclass_details .MuiTypography-root {
  color: #757575;
}
.liveclass_details .MuiGrid-grid-md-1 {
  flex-grow: 0;
  max-width: 5.333% !important;
  flex-basis: 5.333% !important;
}
.liveclass_details .customtable1 .MuiTableCell-root {
  padding: 16px 10px !important;
}
.common_classes .MuiTab-root {
  min-width: 125px !important;
}
.common_classes .MuiTab-textColorPrimary.Mui-selected {
  color: #1875f0 !important;
}
.common_classes #demo-customized-select {
  background-color: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}
.common_classes .customgridxs2cmn .MuiSvgIcon-root {
  color: white !important;
}

.common_classes .topbarcustombtn {
  border-radius: 20px !important;
}

.classes_css .customcardcolor {
  background-color: white !important;
}
.liveclass .react-date-picker {
  width: 100% !important;
}
.liveclass .react-date-picker__wrapper,
.add_livesession .react-timerange-picker__wrapper {
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  padding: 6px 0 !important;
}
.liveclass .react-date-picker__calendar {
  position: relative !important;
  z-index: 9999 !important;
}
.liveclass .customgridcardcs1 .MuiCard-root {
  overflow: unset !important;
}
.common_classes .MuiTab-root {
  font-size: 18px !important;
  font-weight: 700 !important;
}
.textfontcs1 {
  font-weight: 900 !important;
  text-shadow: 1px 0 #000000 !important;
  color: black !important;
}
.textfontcs2 {
  font-weight: 900 !important;
  text-shadow: 1px 0 white !important;
  color: white !important;
}
.textfontcs3 {
  font-weight: 900 !important;

  /* text-shadow:0.4px 0 #000000 !important;  */
  color: black !important;
}
.customselectsd .MuiOutlinedInput-inputMarginDense,
.customselectsd2 .MuiOutlinedInput-inputMarginDense {
  padding-top: 5.5px !important;
  padding-bottom: 5.5px !important;
}
.student_dashbaord .customstdsbtn {
  background: white !important;
  color: black !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 20px !important;
  padding: 2px 12px !important;
  font-size: 12px;
  text-transform: none;
}
.student_dashbaord .cstredeembtn {
  margin-top: 25px !important;
  padding: 2px 10px !important;
  background-color: white !important;
  color: #00ce4c !important;
  border-color: white !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  text-transform: none !important;
}
.student_dashbaord .cstboxstd:hover {
  background-color: aliceblue;
}
.student_dashbaord .cstboxstd2 {
  background-color: aliceblue;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  padding: 2% 2%;
  margin: 2% 2%;
  min-width: 300px;
}
.student_dashbaord .custompaper2:hover {
  background-color: #084FC7 !important;
}
.student_dashbaord .custompaper3:hover {
  background-color: rgb(0, 191, 71) !important;
}
.student_dashbaord .cstbtnst {
  padding: 0px 20px !important;
  font-size: 12px !important;
  min-width: unset !important;
}
.student_dashbaord .cstboxstd {
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  padding: 1% 2%;
  margin: 3.5% 2%;
}
.student_dashbaord .btnprintcustst {
  background-color: #01cf50 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  color: white !important;
  padding: 5px 10px !important;
  text-transform: none !important;
}
.student_dashbaord .btncststd1 {
  background-color: white;
  padding: 4px 8px;
  border-radius: 20px;
  color: #00ce4c;
  text-transform: none;
}
.student_dashbaord .btncststd1:hover {
  color: black;
}
.student_dashbaord .cstdottedborder {
  border: 3px dotted;
  border-radius: 50px;
  width: 25px;
  padding: 15px;
  /* margin-left: 8px !important; */
}
.student_dashbaord .cstborder {
  border: 2px solid #e6fbdf91;
  border-radius: 50px;
  width: 25px;
  padding: 15px;
  /* margin-left: 8px !important; */
}
.customticketmsg {
  padding: 15px 10px;
  border: 1px solid lightgray;
  text-align: left;
}
.ticket_details {
  color: lightgray !important;
}
.topic .MuiTab-wrapper {
  font-size: 18px !important;
  font-weight: 700 !important;
}
.topic .MuiTab-textColorPrimary.Mui-selected {
  color: #1875f0 !important;
}
@media only screen and (min-width: 1400px) {
  .common_classes .customformcmn {
    min-width: 175px !important;
  }
}
.topic .custompdfviewer .rpv-core__page-layer {
  margin: 0 !important;
}
.topic .custompdfviewer .rpv-default-layout__sidebar {
  display: none !important;
}
.topic .custompdfviewer .rpv-default-layout__container {
  border: none !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.liveclass_details .customtablerow .MuiTableCell-root {
  border-bottom: none !important;
}
.liveclass_details .customrowheadstyle {
  color: #000 !important;
}
.liveclass_details .customtable1 {
  box-shadow: none !important;
}
.react-timerange-picker {
  min-width: 250px !important;
  width: 80% !important;
}
