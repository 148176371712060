@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.newFooter {
  /* position: fixed;
  bottom: 0;
  left: 0; */
  width: 100%;
  background: #f7fbfe;
}
.Footer_div {
  width: 100%;
  background: #f7fbfe;
}
.newFooter::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100px;
  height: 1px;
  width: 100%;
  background: #afafb6;
}
.newFooter .content {
  margin: auto;
  padding: 30px 0px;
}
.newFooter .content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.content .top .logo-details {
  color: #38393e;
  font-size: 30px;
  margin-right: 100px;
}
.logo_name {
  font-family: Rubik;
  font-size: 28.9px;
  font-weight: 700;
  line-height: 46.24px;
  text-align: left;
  color: #101218;
}
.logo_name:hover {
  color: #101218;
}
.content .top .media-icons {
  display: flex;
}
.footer_subscribe_button {
  padding: 10px 5px;
  background-color: #4785ff;
  color: white;
  width: Hug (148px) px;
  height: Hug (59px) px;
  padding: 15px 34px;
  gap: 10px;
  border-radius: 100px;
  border: 0px solid transparent;
  opacity: 0px;
  cursor: pointer;
}
.content .top .media-icons a {
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #38393e;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}
.top .media-icons a:nth-child(1) {
  background: #4267b2;
}
.top .media-icons a:nth-child(1):hover {
  color: #4267b2;
  background: #fff;
}
.top .media-icons a:nth-child(2) {
  background: #1da1f2;
}
.top .media-icons a:nth-child(2):hover {
  color: #1da1f2;
  background: #fff;
}
.top .media-icons a:nth-child(3) {
  background: #e1306c;
}
.top .media-icons a:nth-child(3):hover {
  color: #e1306c;
  background: #fff;
}
.top .media-icons a:nth-child(4) {
  background: #0077b5;
}
.top .media-icons a:nth-child(4):hover {
  color: #0077b5;
  background: #fff;
}
.top .media-icons a:nth-child(5) {
  background: #ff0000;
}
.top .media-icons a:nth-child(5):hover {
  color: #ff0000;
  background: #fff;
}
.newFooter .content .link-boxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.newFooter .content .link-boxes .box {
  /* width: calc(100% / 4 - 10px); */
}

#link_box_1 {
  min-width: 300px;
  position: relative;
  right: 40px;
}

#link_name_phone {
  position: relative;
  top: 2px;
}
.link_spacing{
  height: 50px;
}

.content .link-boxes .box .link_name {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  margin-bottom: 10px;
  position: relative;
}
.link-boxes .box .link_name::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 35px;
  background: #fff;
}
.content .link-boxes .box li {
  margin: 6px 0;
  list-style: none;
}
.content .link-boxes .box li a {
  color: #38393e;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.4s ease;
}
.content .link-boxes .box li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.content .link-boxes .input-box {
  margin-right: 55px;
}
.link-boxes .input-box input {
  height: 40px;
  width: calc(100% + 55px);
  outline: none;
  border: 2px solid #afafb6;
  /* background: #140B5C; */
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #38393e;
  margin-top: 5px;
}
.link-boxes .input-box input::placeholder {
  color: #afafb6;
  font-size: 16px;
}
.link-boxes .input-box input[type="button"] {
  background: #fff;
  color: #140b5c;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}
.input-box input[type="button"]:hover {
  opacity: 1;
}
.newFooter .bottom-details {
  width: 100%;
  /* background: #0F0844; */
}
.newFooter .bottom-details .bottom_text {
  max-width: 1250px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
  font-size: 14px;
  font-weight: 300;
  color: #38393e;
  opacity: 0.8;
  text-decoration: none;
}
.bottom-details .bottom_text a:hover {
  opacity: 1;
  text-decoration: underline;
}
.bottom-details .bottom_text a {
  margin-right: 10px;
}
@media (max-width: 900px) {
  .newFooter .content .link-boxes {
    flex-wrap: wrap;
  }
  .newFooter .content .link-boxes .input-box {
    width: 40%;
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
  .newFooter {
    position: relative;
  }
  .content .top .logo-details {
    font-size: 26px;
  }
  .content .top .media-icons a {
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  .newFooter .content .link-boxes .box {
    width: calc(100% / 4 - 10px);
  }
  .newFooter .content .link-boxes .input-box {
    width: 60%;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a {
    font-size: 12px;
  }
}
@media (max-width: 520px) {
  .newFooter::before {
    top: 145px;
  }
  .newFooter .content .top {
    flex-direction: column;
  }
  .content .top .media-icons {
    margin-top: 16px;
  }
  .newFooter .content .link-boxes .box {
    width: calc(100% / 2 - 10px);
  }
  .newFooter .content .link-boxes .input-box {
    width: 100%;
  }
}

@media (max-width: 500px) {
  #link_box_1 {
    min-width: 300px;
    position: relative;
    right: 0px;
  }
}

