@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  font-family: "Poppins";
}

.login-title {
  font-family: "Poppins";
  font-size: 60px;
  font-weight: 500;
}

.gradient-button {
  background: linear-gradient(to right, #6083e2, #db9deb);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}

.gradient-button:hover,
.gradient-button:active {
  background: linear-gradient(to right, #6083e2, #db9deb);
  border: none;
  color: white;
}
