@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=block");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=block");
/* @import url("https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap"); */
@font-face {
  font-family: "Nunito-bold";
  src: url(/src/Components/assets/fonts/Nunito-ExtraBold.ttf);
}
html,
body {
  overflow: unset !important;
  min-width: auto !important;
}
.text-sketch {
  /* font-family: 'Cabin Sketch', cursive !important; */
  /* font-family: 'Calibri',sans-serif!important;  */
  font-family: "Segoe UI", "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.text-sketch1 {
  /* font-family: 'Cabin Sketch', cursive !important; */
  font-family: "Segoe UI", "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.text-nunito-regular {
  font-family: "Nunito", sans-serif !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.text-nunito-bold {
  font-family: "Segoe UI", "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.text-nunito-semibold {
  font-family: "Segoe UI", "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.box-shadow {
  box-shadow: 5px 5px 10px #dcdcdc !important;
}
.text-cursive {
  font-family: "Segoe UI", "Nunito", sans-serif;
}
.text-testimonial-font {
  font-family: "Segoe UI", "Nunito", sans-serif;
}
.text-dark {
  color: #000000 !important;
}
* {
  /* font-family: 'Work Sans', sans-serif !important; */
  /* font-family: 'Calibri', sans-serif !important; */

  font-family: "Segoe UI", "Nunito", sans-serif !important;
}
.text-custom-subhead {
  font-size: 26pt !important;
  font-weight: 500 !important;
}
.text-worksans-regular {
  /* font-family: 'Work Sans', sans-serif!important;
  font-weight: 500!important;
  font-size: 16px; */
  font-family: "Segoe UI", "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.text-worksans-medium {
  /* font-family: 'Work Sans', sans-serif!important;
	font-weight: 700!important; */
  font-family: "Segoe UI", "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.text-worksans-bold {
  font-family: "Segoe UI", "Work Sans", sans-serif !important;
  font-weight: 800 !important;
}
.text-worksans-xtrabold {
  /* font-family: 'Work Sans', sans-serif!important;
	font-weight: 900!important; */
  font-family: "Segoe UI", "Nunito", sans-serif !important;
  font-weight: 600 !important;
}
.text-warning {
  color: #e7ad3b !important;
}
.text-lmswarning {
  color: #4785FF !important;
}
.text-light {
  color: #fff !important;
  /* padding: 0 10px 0 10px !important; */
}
.search-form {
  border: 2px solid #bbc6dd !important;
  border-radius: 30px !important;
  padding: 2px !important;
  display: inline-block !important;
  width: 40% !important;
}
.box-shadow {
  box-shadow: 5px 5px 10px #aaaaaa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiDialogActions-root {
  justify-content: center;
}
.bg-warning {
  background-color: #f4ae2d !important;
  color: #fff !important;
}
.btn-warning {
  background-color: #f4ae2d !important;
  color: #fff !important;
  border-radius: 20px !important;
  min-width: 120px !important;
}
.btn-warning:hover {
  background-color: #01619d !important;
  color: #fff !important;
}
.btn-transparent-outline {
  border: 1px solid #fff !important;
  color: #fff !important;
  background: transparent !important;
  border-radius: 20px !important;
  min-width: 120px !important;
}
.btn-transparent-outline:hover {
  background: rgba(2, 2, 2, 0.2) !important;
}
.MuiTab-textColorPrimary {
  color: #000000 !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #1875f0 !important;
}
.MuiTabs-indicator {
  background: #1875f0 !important;
}
.bg-lmsprimary {
  background: #1875f0 !important;
}
.btn-lmsprimary {
  background-color: #1875f0 !important;
  border: 1px solid#1875F0 !important;
  color: #fff !important;
  border-radius: 20px !important;
  min-width: 120px !important;
  text-transform: capitalize !important;
  box-shadow: unset !important;
}
.text-lms-primary {
  color: #1875f0 !important;
}
.bg-lms-primary {
  background-color: #1875f0 !important;
  color: #fff !important;
}
.btn-lmsprimary:hover {
  background-color: #fff !important;
  color: #1875f0 !important;
  border: 1px solid #1875f0 !important;
}
.btn-lmsprimary-outlined {
  border: 1px solid #1875f0 !important;
  background-color: #fff !important;
  color: #1875f0 !important;
  border-color: #1875f0 !important;
  border-radius: 20px !important;
  min-width: 120px !important;
  text-transform: capitalize !important;
  box-shadow: unset !important;
}
.btn-lmsprimary-outlined:hover {
  background-color: #1875f0 !important;
  color: #fff !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.m-auto {
  margin: auto !important;
}
.text-primary {
  color: #01619d !important;
  font-weight: 600 !important;
}
.btn-primary {
  background-color: #01619d !important;
  color: white !important;
}
.text-primary-app {
  color: #01619d !important;
}
.d-block {
  display: block !important;
}
.hoverCard > .MuiCard-root:hover {
  background-color: #01619d;
  color: white !important;
  text-align: left;
}
.hoverCard > .MuiCard-root:hover .MuiCardContent-root > p {
  color: white !important;
  text-align: left;
}
.hoverCard > .MuiCard-root:hover > .MuiButtonBase-root > .MuiCardMedia-root {
  background-color: white !important;
}
.loginModal > .MuiDialog-container > .MuiPaper-root {
  background: white;
}
.loginModal
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogTitle-root
  > .MuiTypography-root {
  color: #000000;
}
.loginModal
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogTitle-root
  > .MuiButtonBase-root {
  color: white;
  background-color: #b3b3b3;
}
.loginModal > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root {
  background: white;
}
.loginModal > .MuiDialog-container > .MuiPaper-root > .MuiDialogActions-root {
  background: #ececec;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.mt-3 {
  margin-top: 30px !important;
}
.mt-2 {
  margin-top: 15px !important;
}
a:hover {
  text-decoration: none !important;
}
.counter-count h4{
  transition: all 1s ease-in-out;
}
.counter-count h6{
  transition: all 1s ease-in-out;
}
.counter-count:hover h4 {
  transform: scale(1.2);
  transition: all 0.4s ease-in-out;
}
.counter-count:hover h6 {
  transform: scale(0.8);
  transition: all 0.4s ease-in-out;
}
.footer-gen a:hover {
  color: #01619d;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loginModal #outlined-search-label {
  font-size: 14px !important;
}

#registrationModal .MuiGrid-grid-xs-6 {
  padding: 2% !important;
}
.registrationModal > .MuiDialog-container > .MuiDialog-paperScrollBody {
  background: white;
}
.registrationModal #customized-dialog-title > .MuiTypography-h4 {
  color: #000000 !important;
  font-weight: 700 !important;
  font-size: 30px !important;
}
.registrationModal
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root {
  background: white;
  border-radius: 5px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
#customized-dialog-title .MuiButtonBase-root,
.registrationModal .nwbtn > .MuiButtonBase-root {
  background: #fff !important;
  color: rgb(0, 0, 0) !important;
  border: 2px solid rgb(0, 0, 0) !important;
  padding: 5px !important;
  font-size: small !important;
}
#customized-dialog-title .MuiButtonBase-root .MuiSvgIcon-root {
  font-size: large !important;
}
.registrationModal .passwordcs {
  padding-bottom: 0% !important;
}
.csbtn {
  width: unset !important;
}
.registrationModal .btn-primary {
  background-color: #f59107 !important ;
  border-radius: 50px !important;
}

.registrationModal .MuiGrid-grid-xs-12 {
  padding: 2% !important;
}
.registrationModal .MuiContainer-maxWidthLg {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.registrationModal .MuiFormLabel-root,
.registrationModal .MuiTypography-body1 {
  font-size: 14px !important;
}
.fa-fb,
.fa-insta,
.fa-tw,
.fa-yt {
  color: rgba(0, 0, 0, 0.54) !important;
}
.fa-fb:hover {
  color: #385499 !important;
  transform: scale(1.2);
}
.fa-insta:hover {
  color: #c92f7d !important;
  transform: scale(1.2);
}
.fa-tw:hover {
  color: #35b3f7 !important;
  transform: scale(1.2);
}
.fa-yt:hover {
  color: #ff0000 !important;
  transform: scale(1.2);
}
.custom-btn2-1 {
  min-width: 110px !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
}
tr:hover {
  background: rgb(247, 247, 247);
}
.privacy p {
  line-height: 30px !important;
  font-size: 14px !important;
}
.notification-hover:hover {
  background: #ececec !important;
}
.headerSlider .rec-pagination {
  margin-bottom: 15px !important;
  margin-top: -30px !important;
}
.trending-new .rec-pagination {
  display: none;
}
.feedback-rating .MuiSvgIcon-root {
  width: 3em !important;
  height: 3em !important;
}
.student_dashboard_card {
  max-height: 500px;
}
.react-time-picker__wrapper {
  border: none !important;
}
.banner_textContainer {
  margin-bottom: 100px;
}
@media screen and (max-width: 960px) {
  .slider__textContainer {
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 600px) {
  .privacy__heading > h4 {
    padding-left: 20px !important;
  }
  .privacy__content {
    padding: 30px 0px !important;
  }
  .hidden-xs {
    display: none !important;
  }
  .search-form {
    width: 90% !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .p-xs-2 {
    padding: 20px !important;
  }
  .headerSlider .rec-carousel {
    height: 700px !important;
  }
  .text-worksans-xtrabold {
    font-size: 2.75rem !important;
  }
  .rec-arrow {
    width: 25px !important;
    min-width: 25px !important;
    height: 25px !important;
    line-height: 0 !important;
    font-size: 1em !important;
  }
  .teach_bannerBackground {
    min-height: 400px !important;
    max-height: 400px !important;
  }
  .feedback-rating .MuiSvgIcon-root {
    width: 1.5em !important;
    height: 1.5em !important;
  }
  .supportTicket_btnGrid {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center;
  }
  .supportTicket_btn {
    margin: 10px 0 !important;
  }
  .student_dashboard_card {
    max-height: 698px;
  }
  .slider__backgroundImage {
    padding-top: 200px;
  }
  .banner_textContainer {
    position: absolute;
    top: 20px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0 !important;
  }
  .banner_textContainer > h6 > p {
    font-size: 4vw;
  }
  .banner__Text > p > span {
    font-weight: 800;
    font-size: 5vw !important;
    font-family: "Segoe UI", "Nunito-bold", sans-serif !important;
    line-height: 1.75;
  }
  .banner__Text {
    /* text-align: center; */
    font-size: 4.7vw !important;
  }
  .liveclass .banner__Text {
    font-size: 3.7vw !important;
  }
  .liveclass .banner__Text > p > p {
    margin: 10px 0 !important;
  }
  .slider__textContainer > button {
    padding: 5px 10px !important;
    min-width: 0 !important;
  }
  .slider__textContainer > button > span {
    font-size: 0.73rem !important;
  }
}
@media screen and (max-width: 450px) {
  .liveclass .banner__Text > p > p {
    margin: 0px 0 !important;
    margin-bottom: 5px !important;
  }
}
.rec-carousel {
  /* min-height: 520px; */
}
@media only screen and (min-width: 1400px) {
  .student_dashboard_card {
    max-height: 398px;
  }
}
.rec-arrow-left {
  position: absolute;
  left: 20px;
  z-index: 1;
}
.rec-arrow-right {
  position: absolute;
  right: 20px;
  z-index: 1;
}
.mainHeader header > div {
  min-height: 80px !important;
}
@media only screen and (min-width: 1200px) {
  .headerSlider .rec-carousel {
    /* height: calc(86vh - 100px) !important; */
  }
  .custom_grid_tre .rec-item-wrapper {
    height: "auto" !important;
    overflow: unset !important;
  }
  .trending-new .custom_grid_tre {
    margin: 0 85px !important;
  }
  .trending-new .rec-swipable {
    min-height: 500px !important;
  }
  .mainHeader > header {
    box-shadow: none !important;
    /* box-shadow: 5px 5px 10px #dcdcdc !important; */
  }
  .mainHeader header > div {
    min-height: 80px !important;
    /* background: #fbfbfb; */
  }
  .w-200 {
    max-width: 160px !important;
  }
}
@media only screen and (max-width: 380px) {
  .headerSlider .customimgszsl {
    width: 40% !important;
  }
  /* .banner_textContainer {
    left: 12%;
  } */
}
#customized-dialog-title {
  border: 2px solid white !important;
}
.customslider .rec-arrow-left {
  z-index: 2 !important;
}
.customslider .rec-slider-container {
  margin: 0% !important;
}
@media only screen and (max-width: 1300px) {
  .slider__backgroundImage {
    /* background-size: cover !important; */
  }
}
.header__toolbar {
  margin:0px 84px;
}
@media only screen and (max-width: 959px) {
  .common__nav {
    padding: 10px 0 !important;
  }
  .header__toolbar {
    margin-right: 0px;
    margin-left: 0px;
  }
  .privacy__heading > h4 {
    padding-left: 20px !important;
  }
  .privacy__content {
    padding: 30px 0px !important;
  }
  .homepage__countContainer {
    height: max-content !important;
  }
  .learningOption_teach {
    flex-direction: column-reverse;
  }
  .p-xs-2 {
    padding: 40px !important;
  }
  .customslider .custtext {
    background: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .customslider .customvideo {
    position: relative !important;
  }
  .customslider .customtext2 {
    font-size: 18px !important;
  }
  .teach_bannerBackground {
    min-height: 480px !important;
    max-height: 480px !important;
  }
  .instru_profileContent {
    max-height: 830px;
    overflow: scroll;
    overflow-x: hidden;
  }
  .instru_profileCard {
    min-height: 530px;
    max-height: 930px;
    overflow: scroll;
    overflow-x: hidden;
  }
  .student_dashboard_card {
    max-height: 598px;
  }
}
.partners .customimgtc {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}
.partners .customimgtc:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}
.p-0 {
  padding: 0 !important;
}
.pt-5 {
  padding-top: 75px !important;
}
.pb-5 {
  padding-bottom: 70px !important;
}
.pt-3 {
  padding-top: 35px !important;
}
.pb-3 {
  padding-bottom: 35px !important;
}
.pt-2 {
  padding-top: 20px !important;
}
.pb-2 {
  padding-bottom: 20px !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.mb-3 {
  margin-bottom: 30px !important;
}
.mb-2 {
  margin-bottom: 15px !important;
}
#registrationModal .MuiDialogContent-dividers {
  padding-top: 5px !important;
}
.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
  text-align: center;
}

.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 5s;
  text-align: center;
}
.alert-danger {
  /* height: 5px; */
  padding: 0px;
  color: red;
}
.alert-success {
  /* height: 5px; */
  padding: 0px;
  color: green;
}
#zmmtg-root {
  display: none;
}
.fc .fc-button-group > .fc-button {
  border-color: white !important;
}
.calendarclass .react-date-picker {
  width: 100% !important;
}
.calendarclass .react-timerange-picker {
  width: 100% !important;
}
.calendarclass .react-date-picker__wrapper,
.calendarclass .react-timerange-picker__wrapper {
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  padding: 6px 0 !important;
}
.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.8;
  z-index: 99999;
}
.MuiInputBase-input[type="text"]:focus {
  outline: none !important;
}
.MuiInputBase-input:focus {
  outline: none !important;
}

.rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
  background-color: rgb(183, 183, 183) !important;
}
.fc-daygrid-event {
  white-space: unset !important;
  padding: 5px;
  border-radius: unset;
  cursor: pointer;
}
.fc-daygrid-event-harness {
  margin-top: 0 !important;
}
.ytp-title-link {
  display: none !important;
}
.kep-login-facebook {
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.27548vw + 12.71074px);
  text-decoration: none;
  text-transform: unset !important;
  transition: unset !important;
  background-color: #4c69ba;
  width: 100% !important;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  padding: 7px 0 !important;
  position: relative !important;
  /* padding: calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px); */
}
.kep-login-facebook svg {
  position: absolute !important;
  left: -6px !important;
  top: -6px !important;
  font-size: 52px !important;
}
.email-reg-btn {
  border-radius: unset !important;
  padding: 7px 0 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  text-transform: initial !important;
}
.google-login-btn {
  width: 100% !important;
}
.google-login-btn > div {
  margin: 0 !important;
}
.google-login-btn > span {
  width: 100% !important;
  display: block !important;
  margin-left: -38px !important;
  font-size: calc(0.27548vw + 12.71074px);
}
.react-confirm-alert-overlay {
  z-index: 10000 !important;
}
.border-active {
  border: 2px solid green !important;
}
.counter-timer {
  margin: auto !important;
}
.customgridxs9tp > .MuiCard-root {
  min-height: 500px !important;
}
.kep-login-facebook {
  height: 43px !important;
  border-radius: 4px !important;
}
#helppage {
  display: none;
}

/* digital learning pdf viewer print button hide */
.rpv-default-layout__toolbar > div > div:last-child {
  display: none;
}
/* pdf Digitallearning*/
.rpv-core__viewer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.rpv-core__viewer > div {
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.rpv-core__inner-page {
  display: flex;
  justify-content: center;
}

/* instructor profile */
.instru_profileContent {
  max-height: 430px;
  overflow: scroll;
  overflow-x: hidden;
}
.instru_profileCard {
  min-height: 530px;
  max-height: 530px;
  overflow: scroll;
  overflow-x: hidden;
}
.profile_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 50px;
  position: absolute;
  padding: 10px 30px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  min-width: 40%;
  width: max-content;
  max-width: 800px;
  min-height: 80vh;
  height: max-content;
  border-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 2px 6px 1px rgba(87, 87, 87, 0.44);
  z-index: 999;
}
.banner__Text > p {
  line-height: 1.3;
  font-family: "Raleway", sans-serif !important;
  margin: 0;
}
.banner__Text > p > div {
  font-family: "Raleway", sans-serif !important;
}
.banner__Text > p > span {
  font-weight: 800;
  font-size: 2.9vw;
  font-family: "Segoe UI", "Nunito-bold", sans-serif !important;
  line-height: 1.75;
}
.partners__container .rec-carousel-item {
  display: flex;
  align-items: center;
}
.partners__container .rec-slider-container {
  display: flex;
  align-items: center;
}
.partners__container .rec-carousel {
  min-height: 0 !important;
}
.tooltip__container {
  max-width: 300px !important;
  height: max-content !important;
  box-shadow: 2px 3px 12px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 3px 12px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 3px 12px 2px rgba(0, 0, 0, 0.3);
  background-color: white !important;
}
.tooltip__container::after {
  border: none !important;
}

.support__cardHeading > div > span {
  font-size: 21px !important;
  font-weight: 600;
}

.privacy__bannerImg {
  height: 300px;
  width: 100%;
}
.privacy__heading {
  height: 200px;
  width: 100%;
  max-width: 1444px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-size: cover;
  background-position: center center;
}
.privacy__heading > h4 {
  padding-left: 120px;
}
.privacy__content {
  padding: 30px 96px;
}

