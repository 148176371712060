@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

/* newslider.js -------------------------------------------------------------*/
.landing_div {
  width: 80%;
  margin: 20px auto;
}
#Slider_div {
  padding: 100px 0px 0px;
}
#landing_arrow {
  position: absolute;
  left: 0px;
  top: 150px;
}
@media only screen and (max-width: 500px) {
  .landing_div {
    width: 98%;
    margin: auto;
  }
}
.home_text {
  position: relative;
  top: 100px;
}
.home_text h1 {
  text-align: left;
  width: 100%;
  margin: auto;
  font-family: Quicksand;
  font-size: 50px;
  font-weight: 700;
  line-height: 58px;
  text-align: left;
}
.home_text p {
  text-align: left;
  width: 100%;
  margin: auto;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}
#smilinggirl {
  position: relative;
  top: 40px;
  height: 600px;
  width: 600px;
}
@media only screen and (max-width: 1200px) {
  #smilinggirl {
    position: relative;
    top: 220px;
    height: 400px;
    width: 400px;
  }
}

.funandinnovation {
  background: -webkit-linear-gradient(#00b8c5, #3c32c6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Quicksand;
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  text-align: left;
}

.explorecourse {
  background-color: #4785ff;
  padding: 10px 25px;
  border: 0px;
  border-radius: 100px;
  color: white;
  position: absolute;
  left: 0px;
  margin: 30px auto;
  cursor: pointer;
}
.homepage_rating {
  position: relative;
  top: 100px;
}

/* learnwithoutlimits.js -------------------------------------------------------------*/
.learnwithoutlimits {
  min-height: 392px;
  background-color: #084fc7;
  background-position: left top;
  background-size: cover;
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: start;
}
.learn_without_limit_h2 {
  font-family: Quicksand;
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
}
.learn_Inner_div h3 {
  font-family: Inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: justified;
}
.learn_without_limit_span {
  font-family: Inter;
  font-size: 30px;
  font-weight: 500;
  line-height: 22px;
  text-align: justified;
}
.Learn_without_limit_data{
  position: relative;
  top: 60px;
}
.learn_Inner_div {
  color: white;
  width: 80%;
  margin: auto;
  text-align: justify;
}
.learn_without_limit_button {
  width: 170px;
  margin: 40px 20px;
  padding: 10px 10px;
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.008em;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

#learn_without_limit_btn1 {
  background: white;
  color: #084fc7;
  border: 0px solid transparent;
  transition: 0.2s ease-in-out;
}

#learn_without_limit_btn2 {
  width: 270px;
  background: #ffffff23;
  border: 0px solid transparent;
}

@media only screen and (max-width: 1311px) {
  .Learn_without_limit_data{
    position: relative;
    top: 0px;
  }
}
@media only screen and (max-width: 649px) {
  .learn_without_limit_button {
    margin: 10px auto;
    padding: 10px 10px;
  }
  #learn_without_limit_btn1 {
    width: 100%;
  }
  #learn_without_limit_btn2 {
    width: 100%;
  }
}

/* AdaptiveLearning.js _________________________________ */
.adaptive_learning_h2 {
  text-align: center;
  font-family: Manrope;
  font-size: 40px;
  font-weight: 800;
  line-height: 35px;
  margin: 20px auto;
}
.adaptive_learning_p {
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  margin: 20px auto;
}
.adaptive_learning_div {
  height: 340px;
  min-width: 352px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  /* border: 2px solid red; */
}
.adaptive_learning_img {
  position: absolute;
  left: 15px;
  top: 30px;
}
.adaptive_learning_text {
  font-family: Mukta;
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  width: 250px;
  position: absolute;
  left: 130px;
  top: 50px;
}
.adaptive_learning_list {
  position: absolute;
  top: 150px;
}
.adaptive_learning_list li {
  margin: 10px auto;
  font-family: Mukta;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
#adldli1 {
  color: #bd6e89;
}
#adldli2 {
  color: #a58639;
}
#adldli3 {
  color: #28529fe5;
}

#adld1 {
  background-color: #fce4ec;
}
#adld2 {
  background-color: #fff0cb;
}
#adld3 {
  background-color: #ebf2ff;
}

.kidimage {
  position: relative;
  bottom: 40px;
  right: 60px;
}

/* ThinkingSkill ________________________________ */
.thinkingdiv_selection {
  min-width: 500px;
  margin: 50px auto;
  display: flex;
  flex-direction: row;
  transition: 2s ease-in-out;
}
#thinkingdiv_poly {
  height: 30px;
  width: 30px;
  position: relative;
  top: 27px;
  transition: 0.5s ease-in-out;
}
@media only screen and (max-width: 600px) {
  .thinkingdiv_selection {
    transition: 2s ease-in-out;
    width: 370px;
    margin: auto;
    display: flex;
    flex-direction: row;
  }
}

.thinkingdiv_selection span {
  position: relative;
  top: 10px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 16.77px;
  text-align: left;
}
.thinkingdiv_1 {
  min-height: 60px;
  border: 2px solid #ff6d25;
  border-right-width: 1px;
  width: 33%;
  border-radius: 40px 0px 0px 40px;
  transition: 0.2s ease-in-out;
  text-align: center;
}
.thinkingdiv_2 {
  min-height: 60px;
  border: 2px solid #ff6d25;
  width: 33%;
  border-right-width: 1px;
  border-left-width: 1px;
  border-radius: 0px;
  transition: 0.2s ease-in-out;
  text-align: center;
}
.thinkingdiv_3 {
  min-height: 60px;
  border: 2px solid #ff6d25;
  border-left-width: 1px;
  width: 33%;
  border-radius: 0px 40px 40px 0px;
  transition: 0.2s ease-in-out;
  text-align: center;
}
.thinkingdiv_box {
  /* width: 70%; */
  min-height: 400px;
  margin: 20px auto;
  border-radius: 150px 26px 26px 26px;
  background: linear-gradient(90deg, #fef5db 0%, #ffdfdf 100%);
}
.thinkingdiv_box h3 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: left;
}
.thinkingdiv_box p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.61px;
  text-align: left;
}
#thinking_kid {
  display: block;
  position: relative;
  margin: auto;
  height: 80%;
  top: 60px;
}

/* Requirement.js */
.requirement_question_div {
  margin: 80px auto;
  /* border: 2px solid red; */
}
.requirement_div {
  margin: 80px auto;
  min-height: 800px;
  background-image: url("../assets/newImages//Vector\ 4.png");
  background-repeat: no-repeat;
}
.requirement_inner_div {
  min-height: 800px;
  background-image: url("../assets/newImages/k-2-a35f7355b999942ea4b61c8b7639a2f0.png");
  background-repeat: no-repeat;
  background-position: bottom left;
}
#requirement_div {
  min-height: 800px;
  background-image: url("../assets/newImages//Vector\ 3.png");
  background-repeat: no-repeat;
}
#req_arrow {
  position: absolute;
  right: 10%;
}

.requirement_question {
  font-family: Manrope;
  font-size: 32px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: black;
}
.requirement_question_span {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: black;
  position: relative;
  left: 38px;
}
.requirement_choose_span {
  font-family: Manrope;
  font-size: 19px;
  font-weight: 700;
  line-height: 15.1px;
  letter-spacing: 0.02em;
  color: #974e24;
}
.requirement_age_div {
  width: 600px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.req_div {
  transition: 0.2s ease-in-out;
}
.req_div:hover {
  transition: 0.2s ease-in-out;
  box-shadow: 2px 2px 21px 10px #3f241e29;
}
.req_div:active {
  transition: 0.2s ease-in-out;
  box-shadow: 2px 2px 21px 10px #3f241e5a;
  transform: translate3d(1px, 1px, 1px);
}
@media only screen and (max-width: 1000px) {
  .requirement_age_div {
    position: relative;
    right: 50px;
  }
}
@media only screen and (max-width: 899px) {
  .requirement_age_div {
    position: relative;
    left: 30px;
  }
  .requirement_inner_div {
    min-height: 800px;
    background-image: none;
  }
}
@media only screen and (max-width: 716px) {
  .requirement_age_div {
    width: auto;
    margin: auto;
    flex-wrap: wrap;
  }
  .requirement_age_innerdiv1 {
    width: 100%;
    position: relative;
    right: 35px;
    margin: 10px auto;
  }
  .requirement_age_innerdiv2 {
    width: 100%;
    position: relative;
    right: 35px;
    margin: 10px auto;
  }
  .requirement_age_innerdiv3 {
    width: 100%;
    position: relative;
    right: 35px;
    margin: 10px auto;
  }
}

.requirement_age_innerdiv1 {
  padding: 25px 5px;
  background-color: #ffffff;
  border: 1px solid #0000001a;
  border-radius: 25px;
  width: 188px;
}
.requirement_age_innerdiv2 {
  padding: 25px 5px;
  background-color: #ffffff;
  border: 1px solid #0000001a;
  border-radius: 25px;
  width: 188px;
}
.requirement_age_innerdiv3 {
  padding: 25px 5px;
  background-color: #ffffff;
  border: 1px solid #0000001a;
  border-radius: 25px;
  width: 188px;
}
.requirement_choose_innerdiv {
  /* border: 2px solid red; */
  padding: 25px 5px;
  background-color: transparent;
  border-radius: 25px;
  flex-grow: 0;
}
#choose_one {
  flex-grow: 5;
  background-color: #ffffff;
}
.Age_Card_text {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}
.Age_Card_text span {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #ff6d25;
}

/* Testimonial.js__________________________ */

.Testimonial {
  border-radius: 30px;
  min-height: 500px;
  margin: 20px auto;
  padding: 200px 40px 0px;
  background-image: url("../assets/newImages/Group\ 2804\(2\).png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
#testimonial_trophy {
  display: block;
  margin: auto;
}
#trophyvector {
  position: absolute;
  right: 0px;
  top: 55px;
}
#sciencevector {
  position: absolute;
  left: 50px;
  bottom: 0px;
}

.Testimonial_heading {
  font-family: Inter;
  font-size: 25.07px;
  font-weight: 600;
  line-height: 30.34px;
  text-align: center;
  color: #fe9611;
}
.testimonial_background {
  height: 300px;
  width: 100%;
  border: 3px dashed black;
  border-radius: 10.45px;
  margin: auto;
  transform: rotate(1deg);
}

.testimonial_maindiv {
  height: 250px;
  width: 100%;
  background: #fce4ec;
  margin: auto;
  padding: 20px;
  border-radius: 10.45px;
  transform: rotate(-3deg);
  position: relative;
  left: 0px;
}
#tb1 {
  position: relative;
  right: 30px;
}
#tb2 {
  position: relative;
  left: 10px;
}
.testimonial_maindiv p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 31.34px;
  text-align: left;
  position: relative;
  top: 40px;
  left: 30px;
  transform: rotate(2deg);
  width: 66%;
  max-height: 220px;
  overflow: auto;
}

.testimonial_maindiv p:-webkit-scrollbar {
  width: 0;
  height: 0;
}
.testimonial_maindiv p:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

#testimonial_appr1 {
  position: absolute;
  left: 20px;
  top: 10px;
}
#testimonial_appr2 {
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.testimonial_profile_pic {
  position: absolute;
  right: 10px;
  transform: rotate(2deg);
}

@media only screen and (max-width: 700px) {
  .testimonial_profile_pic {
    height: 100px;
  }
}

@media only screen and (max-width: 500px) {
  #tb1 {
    right: 10px;
  }
  #tb2 {
    left: 10px;
  }
}

.testimonial_profile_name {
  position: absolute;
  bottom: 100px;
  right: 20px;
  transform: rotate(2deg);
  font-family: Montserrat;
  font-size: 18.8px;
  font-weight: 700;
  line-height: 22.92px;
  text-align: left;
  color: black;
}

/* FAQ _____________________________ */
#FAQ_Div {
  margin: 70px auto;
}
.accordion_question {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  color: black;
}
.accordion_answer {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: left;
}

#follow_this {
  background-image: url("../assets/newImages/aa0998da75505b19b03a0b93e516bfe4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
.follow_this {
  min-height: 360px;
  padding: 40px;
  position: relative;
  background: #F5F7FA;
  border-radius: 20px;
}
#follow_this_btn {
  width: 155px;
  height: 48px;
  position: absolute;
  left: 50px;
  padding: 0px 24px;
  gap: 12px;
  border: 0px solid white;
  background-color: white;
  border-radius: 20px;
  opacity: 0px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.008em;
  text-align: left;
  color: #3972FF;
  cursor: pointer;

}
.follow_this_text {
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-align: left;
  color: black;
}
.follow_this_text_left {
  font-family: Quicksand;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
}
.follow_this_para_left {
  width: 60%;
  font-family: Quicksand;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
}

.follow_this_box {
  position: relative;
  height: 50px;
}
.follow_this_img {
  position: absolute;
  left: 0px;
}
.follow_this_span2 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  position: absolute;
  left: 60px;
  top: 6px;
}
