@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");

.lms-head {
  font-family: "Rubik";
  font-weight: 650;
  font-size: 27px;
  line-height: 57.6px;
  padding: 0rem;
  color: #000000;
}
.lms-head1 {
  font-family: "Rubik";
  font-weight: 700;
  font-size: 27px;
  line-height: 57.6px;
  padding: 0rem;
  color: rgba(71, 133, 255, 1);
}

/* code added by Vishnudas */
#Navbar_div {
  position: relative;
  bottom: 20px;
}
.logo_div_header {
  min-width: 240px;
  cursor: pointer;
  position: relative;
  left: 10px;
}
/* code added by Vishnudas */

.nav-text {
  color: black;
  font-size: 15px;
  font-weight: bold;
}
.top-nav {
  /* max-width: 1980px; */
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  color: #000000;
  height: 100px;
  /* padding: 1em; */
}

.menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 5;
  border-bottom: 1px solid transparent;
  transition: 0.2s ease-in-out;
}
.menu li a {
  color: black;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 600;
  /* line-height: 18px; */
  letter-spacing: 0.01em;
  text-align: left;
  transition: 0.5s ease-in-out;
}
.menu li a:hover {
  color: #4785ff;
  transition: 0.2s ease-in-out;
}
.menu li a:focus {
 outline: none;
 text-decoration: none;
}
.menu > li {
  margin: 0 1rem;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #000000;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 1500ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(184, 179, 179, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 1166px) {
  /* code added by vishnudas */
  /* for responsive fix */
  #Navbar_div {
    width: 100%;
    padding: 0px 20px;
  }
  /* code added by vishnudas */

  .top-nav {
    justify-content: space-between;
  }

  .menu-button-container {
    display: block;
    position: absolute;
    right: 50px;
    top: 50px;
    height: 100px;
  }

  .menu {
    position: absolute;
    top: 0;
    margin-top: 100px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.476);
  }

  #menu-toggle ~ .menu li {
    display: none;
    color: transparent;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 1000ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  #menu-toggle:checked ~ .menu li {
    display: block;
    color: white;
    height: 3em;
    padding: 0.5em;
    transition: all 1000ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .menu > li {
    /* transition: all 1000ms cubic-bezier(0.23, 1, 0.32, 1); */
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    background-color: #ffffff;
    color: black;
  }

  .menu > li:not(:last-child) {
    /* border-bottom: 1px solid #444; */
  }
}
.login_button {
  border: 0px solid transparent;
}


